import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { Color, Text } from "@adoptaunabuelo/react-components";
import { Menu, Star } from "lucide-react";

const Container = styled.div`
	position: relative;
`;
const ItemNav = styled.div<{ isSelected: boolean }>`
	position: relative;
	height: 42px;
	width: 42px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: ${(props) =>
		props.isSelected ? Color.text.primaryBlack : "transparent"};
	cursor: pointer;
	border-radius: 12px;
	&:hover {
		background: ${Color.text.primaryBlack};
	}
`;
const NotificationView = styled.div`
	display: flex;
	flex-direction: column;
	position: absolute;
	overflow: hidden;
	top: 0px;
	left: 58px;
	background-color: white;
	z-index: 2000;
	border-radius: 12px;
	border: 1px solid ${Color.line.soft};
	box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
	padding: 8px 4px;
	gap: 4px;
`;
const Cell = styled.div<{ state: string }>`
	display: flex;
	flex-direction: row;
	cursor: pointer;
	gap: 18px;
	padding: 4px 8px;
	align-items: center;
	border-radius: 6px;
	justify-content: space-between;
	&:hover {
		background-color: ${Color.background.soft};
	}
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	gap: 8px;
	align-items: center;
`;
const Button = styled.button<{ isSelected: boolean }>`
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background-color: transparent;
	border-radius: 80px;
	height: 28px;
	width: 28px;
	&:hover {
		background-color: ${(props) =>
			props.isSelected
				? Color.line.deepBlueSoft
				: Color.background.secondary};
		cursor: pointer;
	}
`;
const FavView = (props: Props) => {
	const [showNotification, setShowNotification] = useState(false);
	const [favItems, setFavItems] = useState<any>(undefined);

	useEffect(() => {
		//get current favs
		const temp = localStorage.getItem("fav-menu");
		if (temp) setFavItems(JSON.parse(temp));

		//On click outside the filter view
		const handleClickOutside = (e: any) => {
			const element = document.getElementById("fav-menu-view");
			if (element && !element.contains(e.target)) {
				setShowNotification(false);
			}
		};

		document.addEventListener("mousedown", handleClickOutside);

		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, []);

	const setMenu = (item: any) => {
		const temp = {
			...favItems,
			[item.id]: favItems[item.id] ? false : true,
		};
		setFavItems(temp);
		localStorage.setItem("fav-menu", JSON.stringify(temp));
		dispatchEvent(new Event("storage-fav-menu"));
	};

	return (
		<Container id="fav-menu-view">
			<ItemNav
				key={"fav-menu"}
				isSelected={showNotification}
				onClick={() => setShowNotification(!showNotification)}
			>
				<Menu width={20} height={20} color={Color.text.whiteHigh} />
			</ItemNav>
			{showNotification && (
				<NotificationView
					style={{
						top:
							Object.values(favItems).filter((i) => i === true)
								.length > 5
								? "unset"
								: 0,
						bottom:
							Object.values(favItems).filter((i) => i === true)
								.length > 5
								? 0
								: "unset",
					}}
				>
					{props.options.map((item, index) => {
						const isSelected = favItems[item.id];
						return (
							<Cell
								key={"fav-menu-item-" + index}
								state={item.state}
								onClick={() => {
									props.onClick(item);
									setShowNotification(false);
								}}
							>
								<Row>
									<item.icon
										width={20}
										height={20}
										color={Color.text.high}
									/>
									<Text type="p">{item.title}</Text>
								</Row>
								<Button
									isSelected={isSelected}
									onClick={(e) => {
										e.stopPropagation();
										setMenu(item);
									}}
								>
									<Star
										height={18}
										width={18}
										fill={
											isSelected
												? Color.background.secondary
												: "transparent"
										}
										color={
											isSelected
												? Color.background.secondary
												: Color.text.high
										}
									/>
								</Button>
							</Cell>
						);
					})}
				</NotificationView>
			)}
		</Container>
	);
};
export default FavView;
export interface Props {
	options: Array<any>;
	onClick: (item: any) => void;
}

import React, { ComponentPropsWithoutRef, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { ActivityRenderScheme, Letter, UserScheme } from "client-v2";
import html2pdf from "html2pdf.js";
import { Button, Color, Text } from "@adoptaunabuelo/react-components";
import {
	Phone,
	Users,
	Info,
	UserX,
	XCircle,
	Pause,
	Play,
	UserCheck,
	CheckCircle,
	Star,
	Euro,
	GraduationCap,
	Video,
	ListChecks,
	CalendarCheck,
	Coins,
	Repeat,
	Ticket,
	Mail,
	MessageCircle,
	FilePen,
} from "lucide-react";
import letterHTML from "../../assets/letter/letter"
import stamp_empty from "../../assets/letter/stamp_empty.png"
import stamp_retro from "../../assets/letter/stamp_retro.png"

const Line = styled.div`
	display: flex;
	flex: 1;
	width: 1px;
	border-left: 2px dashed ${Color.line.soft};
	min-height: 14px;
	margin-top: 4px;
	margin-bottom: 8px;
	align-self: center;
`;
const ActivityContainer = styled.div`
	display: flex;
	flex-direction: column;
`;
const ActivityItem = styled.div`
	display: flex;
`;
const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
`;
const Column = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	margin-left: 12px;
	padding-top: 0px;
	padding-bottom: 24px;
`;
const IconView = styled.div`
	display: flex;
	flex-direction: column;
`;
const AdminImage = styled.img`
	height: 22px;
	width: 22px;
	border-radius: 40px;
	margin-right: 8px;
	object-fit: cover;
`;
const AudioPlayer = styled.audio`
	height: 32px;
	margin: 12px 0px;
	::-webkit-media-controls-panel {
		background-color: ${Color.background.deepBlueLow};
	}
	::-webkit-media-controls-current-time-display {
		font-family: Poppins;
		font-size: 12px;
	}
	::-webkit-media-controls-time-remaining-display {
		font-family: Poppins;
		font-size: 12px;
	}
`;
const toBase64 = async (url: string): Promise<string> => {
	const response = await fetch(url);
	const blob = await response.blob();
	return new Promise((resolve) => {
		const reader = new FileReader();
		reader.onloadend = () => resolve(reader.result as string);
		reader.readAsDataURL(blob);
	});
};

const History = (props: Props) => {
	const [loading, setLoading] = useState(false);

	const downloadLetter = async (letterId: any) => {
		const letter = (await Letter.getById(letterId)).data;

		if (letter) {
			setLoading(true);
			let html = letterHTML();
			html = html.replace("{{content}}", letter.content);
			html = html.replace(
				"{{name}}",
				`${props.user?.name} ${props.user?.surname}`
			);
			html = html.replace(
				"{{years}}",
				`${moment().diff(
					moment(props.user?.birthday?.iso),
					"year"
				)} años`
			);
			html = html.replace(
				/transform: scale\(([^)]+)\);/,
				`transform: scale(1);`
			);
			html = html.replace(
				`font-family: "Poppins";`,
				`font-family: "${
					letter.customization ? letter.customization.font : "Poppins"
				}";`
			);
			if (props.user?.Coorporate) {
				html = html.replace(
					"{{stamp_img}}",
					stamp_empty
				);
				let logoBase64 = "";
				if (props.user.Coorporate.logo?.url) {
					logoBase64 = await toBase64(
						props.user.Coorporate.logo?.url
					);
				}
				html = html.replace(
					"{{corporate_logo}}",
					`<img src="${logoBase64}"/>`
				);
			} else {
				html = html.replace(
					"{{stamp_img}}",
					stamp_retro
				);
				html = html.replace("{{corporate_logo}}", "");
			}
			if (letter.customization?.stamp) {
				html = html.replace("stamp-aua hide", "stamp-aua show");
			} else {
				html = html.replace("stamp-aua show", "stamp-aua hide");
			}
			if (letter.customization?.personalInfo) {
				html = html.replace("header hide", "header show");
			} else {
				html = html.replace("header show", "header hide");
			}
			if (letter.image) {
				html = html.replace(
					"{{user-image}}",
					`<div class="aua__page-break"></div> <div class="letter-image"><img src="${letter.image}" /></div>`
				);
			} else {
				html = html.replace("{{user-image}}", "");
			}

			//Generate the PDF
			const tempDiv = document.createElement("div");
			tempDiv.innerHTML = html;

			html2pdf()
				.from(tempDiv)
				.set({
					filename: "carta.pdf",
					margin: [10, 12],
					pagebreak: { mode: "css" },
					jsPDF: {
						orientation: "p",
						unit: "mm",
						format: [210, 297],
					},
				})
				.save()
				.get("pdf")
				.then(function (pdf: any) {
					window.open(pdf.output("bloburl"), "_blank");
					setLoading(false);
				});
		}
	};

	return (
		<ActivityContainer>
			{props.activities &&
				props.activities.map((item, index) => (
					<ActivityItem
						key={"history-" + item.objectId + "-" + index}
					>
						<IconView>
							{item.type === "call" ? (
								<Phone
									height={20}
									width={20}
									color={Color.text.primary}
								/>
							) : item.type === "video" ? (
								<Video
									height={20}
									width={20}
									color={Color.text.primary}
								/>
							) : item.type === "match" ? (
								<Users
									height={20}
									width={20}
									color={Color.text.primary}
								/>
							) : item.type === "matchCanceled" ? (
								<UserX
									height={20}
									width={20}
									color={Color.status.color.error}
								/>
							) : item.type === "subscriptionCanceled" ||
							  item.type === "banned" ? (
								<XCircle
									height={20}
									width={20}
									color={Color.status.color.error}
								/>
							) : item.type === "pauseSubscription" ||
							  item.type === "grandpaPaused" ? (
								<Pause
									height={20}
									width={20}
									color={Color.status.color.warning}
								/>
							) : item.type === "unpauseSubscription" ||
							  item.type === "unpauseGrandpa" ? (
								<Play
									height={20}
									width={20}
									color={Color.status.color.success}
								/>
							) : item.type === "subscriptionActivated" ? (
								<CheckCircle
									height={20}
									width={20}
									color={Color.status.color.success}
								/>
							) : item.type === "registerCompleted" ? (
								<UserCheck
									height={20}
									width={20}
									color={Color.status.color.success}
								/>
							) : item.type === "register" ? (
								<UserCheck
									height={20}
									width={20}
									color={Color.text.primary}
								/>
							) : item.type === "paymentFailed" ? (
								<Euro
									height={20}
									width={20}
									color={Color.status.color.error}
								/>
							) : item.type === "training" ? (
								<GraduationCap
									height={20}
									width={20}
									color={Color.status.color.success}
								/>
							) : item.type === "review" ? (
								<Star
									height={20}
									width={20}
									color={Color.status.color.warning}
								/>
							) : item.type === "test" ? (
								<ListChecks
									height={20}
									width={20}
									color={Color.status.color.success}
								/>
							) : item.type === "reDoTest" ? (
								<Repeat
									height={20}
									width={20}
									color={Color.status.color.warning}
								/>
							) : item.type === "eventRegistration" ||
							  item.type === "visit" ? (
								<CalendarCheck
									height={20}
									width={20}
									color={Color.status.color.success}
								/>
							) : item.type === "payment" ? (
								<Coins
									height={20}
									width={20}
									color={Color.status.color.success}
								/>
							) : item.type === "ticket" ? (
								<Ticket
									height={20}
									width={20}
									color={Color.status.color.success}
								/>
							) : item.type === "removeTicket" ? (
								<Ticket
									height={20}
									width={20}
									color={Color.status.color.error}
								/>
							) : item.type === "subscriptionUpdated" ? (
								<CheckCircle
									height={20}
									width={20}
									color={Color.status.color.warning}
								/>
							) : item.type === "letter" ? (
								<Mail
									height={20}
									width={20}
									color={Color.status.color.success}
								/>
							) : item.type === "message" ? (
								<MessageCircle
									height={20}
									width={20}
									color={Color.text.primary}
								/>
							) : item.type === "commitment" ? (
								<FilePen
									height={20}
									width={20}
									color={Color.text.primary}
								/>
							) : (
								<Info
									height={20}
									width={20}
									color={Color.text.medium}
								/>
							)}
							<Line />
						</IconView>
						<Column>
							<Row style={{ alignItems: "center" }}>
								<Text
									type="p2"
									weight={"medium"}
									style={{ flex: 1 }}
								>
									{item.title}
								</Text>
								<Text
									type="c1"
									style={{ color: Color.text.high }}
								>
									{moment(item.timestamp).format(
										"HH:mm DD/MM/YY"
									)}
								</Text>
							</Row>
							<Row
								style={{ alignItems: "self-end", marginTop: 2 }}
							>
								<Text
									type="p2"
									style={{ color: Color.text.high }}
								>
									{item.subtitle}
								</Text>
							</Row>
							{item.recordingUrl && (
								<AudioPlayer
									src={item.recordingUrl}
									controls={true}
								/>
							)}
							{item.Admin && (
								<Row
									style={{
										alignItems: "center",
										marginTop: 4,
									}}
								>
									<AdminImage src={item.Admin.image} />
									<Text type="c1">{item.Admin.name}</Text>
								</Row>
							)}
							{item.letter && (
								<Button
									style={{ padding: 0, marginTop: 8 }}
									design={"call-to-action"}
									size="small"
									loading={loading}
									onClick={() =>
										downloadLetter(item.letter)
									}
								>
									Descargar carta
								</Button>
							)}
						</Column>
					</ActivityItem>
				))}
		</ActivityContainer>
	);
};
export default History;
export interface Props extends ComponentPropsWithoutRef<"div"> {
	activities: Array<ActivityRenderScheme>;
	user?: UserScheme;
}

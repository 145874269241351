import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Challenge } from "client-v2";
import {
	Input,
	Text,
	Button,
	Feedback,
	TextArea,
	Color,
	Checkbox,
} from "@adoptaunabuelo/react-components";
import AdminBar from "../../../components/Nav/AdminBar";
import moment from "moment";

const Container = styled.div`
	position: relative;
	display: flex;
	width: -webkit-fill-available;
	height: 100vh;
	flex-direction: column;
	padding: 0px 32px 32px;
`;
const RegisterContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
`;
const RegisterView = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	background-color: white;
	padding: 24px 48px;
	border-radius: 12px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

const Col = styled.div`
	display: flex;
	flex-direction: column;
`;

const Image = styled.img`
	display: flex;
	flex: 1;
	height: 200px;
	object-fit: cover;
	border-radius: 12px;
	margin-bottom: 24px;
	background-color: ${Color.background.soft};
`;

const Register = (props: Props) => {
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);
	const [formData, setFormData] = useState({
		title: "",
		description: "",
		objective: "",
		endDate: "",
		programs: "",
		subtipos: "",
		image: "",
	});

	const onSaveClick = async () => {
		if (!formData.image) {
			setError("Debes seleccionar una imagen");
			return;
		}

		if (!formData.title) {
			setError("Debes añadir el título del evento");
			return;
		}

		if (!formData.description) {
			setError("Debes añadir la descripción del evento");
			return;
		}

		if (!formData.objective) {
			setError("Debes añadir el objetivo del evento");
			return;
		}

		if (!formData.endDate) {
			setError("Debes añadir la fecha de fin del evento");
			return;
		}

		if (!formData.programs) {
			setError("Debes seleccionar un programa");
			return;
		}

		if (!formData.subtipos) {
			setError("Debes seleccionar un subtipo");
			return;
		}

		Challenge.create({
			type: formData.programs as "birthday" | "dream",
			title: formData.title,
			history: formData.description,
			goal: Number(formData.objective),
			endDate: new Date(formData.endDate),
			subtype: formData.subtipos as "feed" | "cross-selling",
			image: formData.image,
		})
			.then((result) => {
				navigate("/reto/perfil?id=" + result.data.objectId);
				setLoading(false);
			})
			.catch((e: string) => {
				setLoading(false);
				setError(e);
			});
	};

	const handleChange = (field: string, value: any) => {
		setFormData((prev) => {
			const newFormData = { ...prev, [field]: value };

			if (field === "programs" && value !== "dream") {
				newFormData.subtipos = "feed";
			}

			return newFormData;
		});
	};

	return (
		<Container>
			<Feedback
				isVisible={!!error}
				type="error"
				text={error || ""}
				onClose={() => setError(undefined)}
			/>
			<AdminBar
				style={{ marginBottom: 16 }}
				title="Registro de sueño"
				goBack={() => navigate(-1)}
			/>
			<RegisterContainer>
				<RegisterView>
					<Text
						type="h3"
						weight="semibold"
						style={{ marginTop: 12, marginBottom: 24 }}
					>
						Datos
					</Text>

					<Row
						style={{
							alignItems: "center",
							justifyContent: "space-between",
							gap: 8,
						}}
					>
						<Image
							src={
								formData.image ||
								"https://via.placeholder.com/1000?text=Insert+Image"
							}
							alt="Selecciona una imagen"
						/>
						<Input
							type="image"
							maxHeight={1000}
							maxWidth={1000}
							label={"Añadir foto"}
							onChange={(file: string) =>
								setFormData({
									...formData,
									image: file,
								})
							}
						/>
					</Row>
					<Row style={{ marginBottom: 12 }}>
						<Input
							type="text"
							design={"secondary"}
							containerStyle={{ flex: 1 }}
							placeholder="Título"
							onChange={(e) =>
								handleChange("title", e.target.value)
							}
						/>
					</Row>
					<Row style={{ marginBottom: 12 }}>
						<TextArea
							type="default"
							style={{ width: 575 }}
							design={"secondary"}
							placeholder="Descripción"
							onChange={(e) =>
								handleChange("description", e.target.value)
							}
						/>
					</Row>
					<Row style={{ marginBottom: 12, gap: 12 }}>
						<Input
							type="number"
							design={"secondary"}
							containerStyle={{ flex: 1 }}
							placeholder={"Objetivo €"}
							onChange={(e) =>
								handleChange("objective", e.target.value)
							}
						/>
						<Input
							design="secondary"
							containerStyle={{ flex: 1 }}
							placeholder="Fecha de fin"
							type="date"
							hideCalendar={false}
							onChange={(e: any) => handleChange("endDate", e)}
						/>
					</Row>
					<Col>
						<Text
							type="p2"
							style={{ marginBottom: 12, color: Color.text.high }}
						>
							Tipo
						</Text>
						<Checkbox
							type="single"
							shape="circle"
							options={[
								{
									id: "dream",
									label: "Sueños",
								},
								{
									id: "birthday",
									label: "Cumpleaños",
								},
							]}
							onChange={(result: { id: string }[]) =>
								handleChange("programs", result[0]?.id || "")
							}
						/>
					</Col>
					{formData.programs === "dream" && (
						<Col style={{ marginTop: 12 }}>
							<Text type="p2" style={{ marginBottom: 12 }}>
								Sub-Tipo
							</Text>
							<Checkbox
								type="single"
								shape="circle"
								options={[
									{ id: "feed", label: "Feed" },
									{
										id: "cross-selling",
										label: "Venta cruzada",
									},
								]}
								onChange={(result: { id: string }[]) =>
									handleChange(
										"subtipos",
										result[0]?.id || ""
									)
								}
							/>
						</Col>
					)}

					<Row
						style={{
							flex: 1,
							justifyContent: "flex-end",
							alignItems: "flex-end",
						}}
					>
						<Button loading={loading} onClick={onSaveClick}>
							Guardar
						</Button>
					</Row>
				</RegisterView>
			</RegisterContainer>
		</Container>
	);
};
export default Register;
export interface Props {}

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
	Activity,
	User,
	AdminTask,
	UserScheme,
	AdminTaskScheme,
} from "client-v2";
import moment from "moment";

import {
	RadioButton,
	Text,
	Button,
	Color,
	Input,
	TextArea,
	Avatar,
	ColorV2,
	Checkbox,
} from "@adoptaunabuelo/react-components";
import Countdown from "../Countdown/Countdown";
import { Phone, Pause, Pencil } from "lucide-react";


const Container = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const DataContainer = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const Footer = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 12px 0px;
	border-top: 1px solid ${Color.line.soft};
`;
const Error = styled.div`
	position: absolute;
	bottom: 8px;
	right: 8px;
	left: 8px;
	display: flex;
	flex-direction: row;
	flex: 1;
	padding: 8px;
	border-radius: 6px;
	background-color: ${Color.background.redLow};
`;
const Column = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	margin-left: 12px;
`;
const Cell = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;

const Steps = (props: Props) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);
	const [selection, setSelection] = useState<{
		option?: string;
		cancelOption?: string;
		notValidLeadOption?: string;
		note?: string;
		reminderTitle?: string;
		reminderDate?: string;
		reminderTime?: string;
		changeAdmin: boolean;
	}>({
		option: undefined,
		cancelOption: undefined,
		notValidLeadOption: undefined,
		note: undefined,
		reminderTitle: undefined,
		reminderDate: undefined,
		reminderTime: undefined,
		changeAdmin: false,
	});
	const [step, setStep] = useState<string | undefined>("option");

	useEffect(() => {
		setSelection({
			option: undefined,
			note: undefined,
			reminderTitle: undefined,
			reminderDate: undefined,
			reminderTime: undefined,
			changeAdmin: false,
		});
		setStep("option");
	}, []);

	const onOptionChange = (ops: Array<any>) => {
		setError(undefined);
		const opId = ops[0].id;
		setSelection({
			...selection,
			option: opId,
		});
	};

	const onCancelOptionChange = (ops: Array<any>) => {
		setError(undefined);
		const opId = ops[0].id;
		setSelection({
			...selection,
			cancelOption: opId,
		});
	};

	const onNotValidLeadOptionChange = (ops: Array<any>) => {
		setError(undefined);
		const opId = ops[0].id;
		setSelection({
			...selection,
			notValidLeadOption: opId,
		});
	};

	const onReminderTitleChange = (e: any) => {
		setError(undefined);
		setSelection({
			...selection,
			reminderTitle: e.target.value,
		});
	};

	const onDateChange = (value: any) => {
		setError(undefined);
		setSelection({
			...selection,
			reminderDate: moment(value).format("DD/MM/YYYY"),
		});
	};

	const onTimeChange = (e: any) => {
		setError(undefined);
		setSelection({
			...selection,
			reminderTime: e.target.value,
		});
	};

	const onNoteChange = (e: any) => {
		setError(undefined);
		setSelection({
			...selection,
			note: e.target.value,
		});
	};

	const onNextClick = async () => {
		const nextStep = getStep();
		if (step === "option") {
			if (selection.option === "no-answear") {
				if (props.currentCall.adminTask.state === "pending") {
					await changeTaskState("inProgress");
				}
				const result = await Activity.get({
					type: "call",
					data: {
						adminTaskId: props.currentCall.adminTask.objectId,
					},
				});
				if (result.data.length === 2 || result.data.length === 4) {
					if (props.nextCall) setStep(nextStep);
					else props.onSave("pause");
				} else if (result.data.length >= 5) {
					await changeTaskState("canceled");
					props.onSave("next");
				} else {
					props.onSave("repeat");
				}
			} else if (selection.option === "reminder") {
				await changeTaskState("inProgress");
				setStep(nextStep);
			} else if (selection.option === "donation") {
				await changeTaskState("done");
				setStep(nextStep);
			} else if (selection.option === "no-donation") {
				setStep(nextStep);
			} else if (selection.option === "hot") {
				await changeTaskState("hot");
				setStep(nextStep);
			} else {
				setError("Selecciona una de las opciones para continuar");
			}
		} else if (step === "motivation") {
			await changeTaskState("canceled");
			setStep(nextStep);
		} else if (step === "motivation_not_valid") {
			changeUserData();
			setStep(nextStep);
		} else if (step === "reminder") {
			if (
				selection.reminderTime &&
				selection.reminderDate &&
				selection.reminderTitle
			) {
				const momentDate = moment(
					selection.reminderDate + " " + selection.reminderTime,
					"DD/MM/YYYY HH:mm"
				);
				const selectedDate = momentDate.toDate();
				const today = new Date();
				if (selectedDate > today) {
					setStep(nextStep);
				} else {
					setError(
						"No puedes crear un recordatorio para una fecha anterior a hoy"
					);
				}
			} else {
				if (selection.option === "reminder")
					setError(
						"Debes añadir un recordatorio para llamar al cliente más adelante"
					);
				else setStep(nextStep);
			}
		} else if (step === "note") {
			setStep(nextStep);
		} else if (step === "resume") {
			//Create the reminder & the note if needed
			await createNote();

			if (props.nextCall) setStep(nextStep);
			else props.onSave("pause");
		}
	};

	const getStep = () => {
		if (step === "option") {
			if (selection.option === "no-answear") {
				if (props.nextCall) return "nextCall";
				else return "pause";
			} else if (selection.option === "reminder") {
				return "reminder";
			} else if (selection.option === "donation") {
				return "note";
			} else if (selection.option === "no-donation") {
				return "motivation";
			} else if (selection.option === "hot") {
				return "note";
			} else {
				setError("Selecciona una de las opciones para continuar");
				return undefined;
			}
		} else if (step === "motivation") {
			if (selection.cancelOption === "not_valid")
				return "motivation_not_valid";
			else return "note";
		} else if (step === "motivation_not_valid") {
			return "note";
		} else if (step === "reminder") {
			if (selection.note) return "resume";
			else return "note";
		} else if (step === "note") {
			return "resume";
		} else if (step === "resume") {
			if (props.nextCall) return "nextCall";
			else return "pause";
		} else return undefined;
	};

	const changeTaskState = async (
		state: "inProgress" | "done" | "canceled" | "hot"
	) => {
		setLoading(true);
		if (state === "hot") {
			await AdminTask.set(props.currentCall.adminTask.objectId, {
				priority: "hot",
				state: "inProgress",
			});
		} else {
			await AdminTask.set(props.currentCall.adminTask.objectId, {
				state: state,
				adminId: props.admin.objectId,
				motivation: selection.cancelOption,
			});
		}
		setLoading(false);
	};

	const changeUserData = async () => {
		setLoading(true);
		await User.set(props.currentCall.user.objectId, {
			internalData: {
				notValidLead: selection.notValidLeadOption,
			},
		});
		setLoading(false);
	};

	const createNote = async () => {
		if (selection.note) {
			setLoading(true);
			const result = await Activity.create({
				fromId: props.currentCall.user.objectId,
				adminId: props.admin.objectId,
				type: "note",
				data: {
					AdminTask: props.currentCall.adminTask.objectId,
					note: selection.note,
				},
			});
			setLoading(false);
			return result;
		} else {
			return null;
		}
	};

	return (
		<Container>
			<DataContainer>
				<Text type="p" weight="semibold">
					{step === "option"
						? "¿Qué tal ha ido la llamada?"
						: step === "motivation"
						? "¿Por qué no dona?"
						: step === "reminder"
						? "Añade un recordatorio"
						: step === "note"
						? "¿Quieres dejar una nota en la tarea?"
						: step === "resume"
						? "Confirmación"
						: step === "motivation_not_valid"
						? "¿Por qué no es válido?"
						: ""}
				</Text>
				{step === "option" ? (
					<RadioButton
						type="single"
						options={[
							{
								id: "no-answear",
								children: (
									<Text type="p2">
										No ha cogido el teléfono
									</Text>
								),
							},
							{
								id: "reminder",
								children: (
									<Text type="p2">
										Quiere que llamemos más adelante
									</Text>
								),
							},
							{
								id: "no-donation",
								children: (
									<Text type="p2">No quiere donar</Text>
								),
							},
							{
								id: "donation",
								children: <Text type="p2">Ha donado</Text>,
							},
							{
								id: "hot",
								children: (
									<Text
										type="p2"
										style={{
											backgroundColor:
												ColorV2.surface.secondarySoft,
											padding: "2px 4px",
										}}
									>
										Es un lead caliente 🔥
									</Text>
								),
							},
						]}
						onChange={onOptionChange}
					/>
				) : step === "motivation" ? (
					<RadioButton
						type="single"
						cellStyle={{ margin: "12px 0px" }}
						options={[
							{
								id: "confidence",
								children: (
									<Text type="p2">No confía en nosotros</Text>
								),
							},
							{
								id: "econommic",
								children: (
									<Text type="p2">
										Mala situación económica
									</Text>
								),
							},
							{
								id: "later",
								children: (
									<Text type="p2">
										Quiere donar más adelante
									</Text>
								),
							},
							{
								id: "not_agree",
								children: (
									<Text type="p2">
										No comparte cómo se utiliza el dinero
									</Text>
								),
							},
							{
								id: "other_ong",
								children: (
									<Text type="p2">
										Está colaborando con otra ONG
									</Text>
								),
							},
							{
								id: "not_working",
								children: <Text type="p2">Está en paro</Text>,
							},
							{
								id: "not_valid",
								children: (
									<Text
										type="p2"
										style={{
											backgroundColor:
												ColorV2.surface.redSoft,
											padding: "2px 4px",
										}}
									>
										Lead no válido
									</Text>
								),
							},
						]}
						onChange={onCancelOptionChange}
					/>
				) : step === "motivation_not_valid" ? (
					<RadioButton
						type="single"
						options={[
							{
								id: "minor",
								children: (
									<Text type="p2">Es menor de edad</Text>
								),
							},
							{
								id: "wrong_phone",
								children: (
									<Text type="p2">
										El número de teléfono no es válido
									</Text>
								),
							},
							{
								id: "no_call",
								children: (
									<Text type="p2">
										No quiere que le llamemos más
									</Text>
								),
							},
							{
								id: "student",
								children: <Text type="p2">Es estudiante</Text>,
							},
						]}
						onChange={onNotValidLeadOptionChange}
					/>
				) : step === "reminder" ? (
					<div
						style={{
							gap: 12,
							display: "flex",
							flex: 1,
							flexDirection: "column",
							padding: "12px 0px",
						}}
					>
						<Input
							type="text"
							placeholder="Título del recordatorio"
							onChange={onReminderTitleChange}
						/>
						<div
							style={{
								gap: 12,
								display: "flex",
								flexDirection: "row",
							}}
						>
							<Input
								type="date"
								containerStyle={{ display: "flex", flex: 1 }}
								placeholder="Fecha"
								design="primary"
								hideCalendar={false}
								onChange={onDateChange}
							/>
							<Input
								type="time"
								placeholder="Hora"
								onChange={onTimeChange}
							/>
						</div>
						{props.admin.data?.departmentSchedule && (
							<div
								style={{
									display: "flex",
									flex: 1,
									alignItems: "flex-end",
								}}
							>
								<Checkbox
									type="single"
									options={[
										{
											id: "change-admin",
											label:
												"Pasar lead a turno de " +
												(props.admin.data
													.departmentSchedule ===
												"morning"
													? "tarde"
													: "mañana"),
										},
									]}
									onChange={(op) =>
										setSelection({
											...selection,
											changeAdmin:
												op.length > 0 ? true : false,
										})
									}
								/>
							</div>
						)}
					</div>
				) : step === "note" ? (
					<div
						style={{
							gap: 12,
							display: "flex",
							flexDirection: "column",
							padding: "12px 0px",
						}}
					>
						<TextArea
							type="default"
							placeholder="Por ejemplo: El cliente está animado a colaborar"
							onChange={onNoteChange}
						/>
					</div>
				) : step === "resume" ? (
					<div
						style={{
							marginTop: 24,
							gap: 8,
							display: "flex",
							flexDirection: "column",
						}}
					>
						<Cell>
							<Text
								type="c1"
								style={{
									display: "flex",
									width: 120,
									color: ColorV2.text.neutralMedium,
								}}
							>
								Selección
							</Text>
							<Text
								type="c1"
								style={{ display: "flex", flex: 1 }}
							>
								{selection.option === "no-answear"
									? "No ha cogido el teléfono"
									: selection.option === "reminder"
									? "Quiere que llamemos más adelante"
									: selection.option === "no-donation"
									? "No quiere donar"
									: selection.option === "donation"
									? "Ha donado"
									: selection.option === "hot"
									? "Es un lead caliente"
									: "-"}
							</Text>
						</Cell>
						{selection.option !== "no-donation" &&
							selection.option !== "donation" &&
							selection.option !== "hot" && (
								<Cell>
									<Text
										type="c1"
										style={{
											display: "flex",
											width: 120,
											color: ColorV2.text.neutralMedium,
										}}
									>
										Recordatorio
									</Text>
									<Text
										type="c1"
										style={{ display: "flex", flex: 1 }}
									>
										{selection.reminderDate &&
										selection.reminderTime
											? moment(
													selection.reminderDate +
														" " +
														selection.reminderTime,
													"DD/MM/YYYY HH:mm"
											  ).format("DD/MM/YYYY HH:mm")
											: "-"}
									</Text>
									<Button
										design="image"
										icon={<Pencil height={16} width={16} />}
										onClick={() => setStep("reminder")}
									/>
								</Cell>
							)}
						<Cell>
							<Text
								type="c1"
								style={{
									display: "flex",
									width: 120,
									color: ColorV2.text.neutralMedium,
								}}
							>
								Nota
							</Text>
							<Text
								type="c1"
								style={{ display: "flex", flex: 1 }}
							>
								{selection.note ? selection.note : "-"}
							</Text>
							<Button
								design="image"
								icon={<Pencil height={16} width={16} />}
								onClick={() => setStep("note")}
							/>
						</Cell>
					</div>
				) : step === "nextCall" && props.nextCall ? (
					<div
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center",
						}}
					>
						<Avatar
							style={{ height: 48, width: 48 }}
							name={props.nextCall.user.name}
							icon={props.nextCall.user.image?.url}
						/>
						<Column>
							<Text type="b1" weight={"medium"}>
								{props.nextCall.user.name +
									" " +
									props.nextCall.user.surname}
							</Text>
							<Text type="p2" style={{ color: Color.text.high }}>
								{props.nextCall.adminTask.typeTitle}
							</Text>
						</Column>
					</div>
				) : null}
			</DataContainer>
			<Footer>
				<Text
					type="c1"
					style={{
						display: "flex",
						flexDirection: "row",
						gap: 4,
						alignItems: "center",
					}}
				>
					Sig. llamada en
					<Countdown textType={"c1"} initSeconds={60} />
				</Text>
				{step === "nextCall" ? (
					<div style={{ display: "flex", gap: 8 }}>
						<Button
							design="image"
							icon={
								<Pause
									height={24}
									width={24}
									fill={Color.text.deepBlue}
									color={"transparent"}
								/>
							}
							style={{
								backgroundColor: Color.background.soft,
								borderRadius: 40,
							}}
							onClick={() => props.onSave("pause")}
						/>
						<Button
							design="image"
							icon={
								<Phone
									height={24}
									width={24}
									fill={"white"}
									color={"transparent"}
								/>
							}
							style={{
								backgroundColor: Color.status.color.success,
								borderRadius: 40,
							}}
							onClick={() => props.onSave("next")}
						/>
					</div>
				) : (
					<Button
						size="small"
						loading={loading}
						onClick={onNextClick}
					>
						Siguiente
					</Button>
				)}
			</Footer>
			{error && (
				<Error>
					<Text type="c1" style={{ color: Color.status.color.error }}>
						{error}
					</Text>
				</Error>
			)}
		</Container>
	);
};
export default Steps;
export interface Props {
	admin: UserScheme;
	currentCall: {
		user: UserScheme;
		adminTask: AdminTaskScheme;
	};
	nextCall?: {
		user: UserScheme;
		adminTask: AdminTaskScheme;
	};
	onSave: (state: "repeat" | "next" | "pause") => void;
}

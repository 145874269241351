import React, { useEffect, useState } from "react";
import styled from 'styled-components';
import moment from 'moment';
import { CoorporateScheme } from 'client-v2';

import Card from '../../../components/Containers/Card';
import { Text, Color, Menu, Label, Avatar } from '@adoptaunabuelo/react-components'
import { Menu as MenuIcon, Edit2 } from 'lucide-react';
import CorporateInfoModal from '../../../components/Modals/CorporateInfoModal';

const TitleHeader = styled.div`
	display: flex;
	margin-bottom: 13px;
	align-items: center;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`

const SectionMain = (props: Props) =>{

	const [ showEditModal, setShowEditModal ] = useState(false);
	const [ corporateOptions, setCorporateOptions ] = useState<Array<{
		id: string;
		label: string;
		icon?: React.ReactElement;
	}>>([]);

	useEffect(() =>{
        generateCoorporateOptions();
	},[]);
	
	const generateCoorporateOptions = () =>{
		let tmpArray = [
			{
				id:"edit-profile",
				label:"Editar",
				icon: <Edit2 height={20} width={20}/>
			}
		]
		setCorporateOptions(tmpArray)
	}

	const userOptionClick = (item: any) =>{
		if(item.id==="edit-profile"){
			setShowEditModal(true);
		}
	}

	return (
        <>
		<CorporateInfoModal
			isVisible={showEditModal}
			coorporate={props.coorporate}
			onSave={(coorporate) => {
				setShowEditModal(false);
				props.onCoorporateChanged(coorporate)
			}}
			onClose={() => setShowEditModal(false)}
		/>
        <Card style={{marginBottom:16}}>
            <TitleHeader>
                <Avatar
					name={props.coorporate.name} 
					icon={props.coorporate.logo && props.coorporate.logo.url}
					clickable={props.coorporate.logo ? true : false}
				/>
				<Row style={{flex: 1, justifyContent: 'flex-end', marginRight: 24}}>
					<Label 
						text={props.coorporate.active ? 'Activa' : 'Inactiva'}
						color={props.coorporate.active ? '#2D55B5' : Color.status.color.error}
						style={{
							backgroundColor: props.coorporate.active ? '#EBF9FF' : Color.status.color.errorDefault
						}}
					/>
				</Row>
				<Menu
					id="user-options"
					position={'bottom-right'}
					icon={<MenuIcon/>}
					options={corporateOptions}
					onClick={(option) => userOptionClick(option)}
				/>
            </TitleHeader>
            <Text type='p' style={{marginBottom:4}} weight={"semibold"}>
				{props.coorporate.name}
			</Text>
			<Row
				style={{alignItems: 'center', marginTop: 12}}
			>
				<Text type='p' style={{display: 'flex', flex: 1, fontSize:12, color: Color.text.high}} weight={"semibold"}>
					Registrada el {moment(props.coorporate.createdAt.iso).format('DD/MM/YYYY')}
				</Text>
			</Row>
        </Card>
        </>
	);
}

export default SectionMain;
export interface Props{
    coorporate: CoorporateScheme,
	onCoorporateChanged: (residence: CoorporateScheme) => void
}
import React, { useState } from "react";
import styled from "styled-components";
import { Challenge, ChallengeScheme } from "client-v2";

import {
	Input,
	Color,
	Modal,
	TextArea,
} from "@adoptaunabuelo/react-components";

const Row = styled.div`
	display: flex;
	flex-direction: row;
	gap: 18px;
	margin-bottom: 18px;
`;
const Image = styled.img`
	display: flex;
	flex: 1;
	height: 200px;
	object-fit: cover;
	border-radius: 12px;
	background-color: ${Color.background.soft};
`;

const InfoModal = (props: Props) => {
	const [loading, setLoading] = useState(false);
	const [selection, setSelection] = useState({
		title: props.challenge.title,
		history: props.challenge.history,
		goal: props.challenge.goal,
		image: props.challenge.image ? props.challenge.image.url : undefined,
		changeImage: false,
	});
	const [error, setError] = useState<string | undefined>(undefined);

	const onSave = async () => {
		if (
			selection.title &&
			selection.history &&
			selection.goal >= 0 &&
			selection.image
		) {
			setLoading(true);
			Challenge.set(props.challenge.objectId, {
				title: selection.title,
				goal: selection.goal,
				history: selection.history,
				image: selection.changeImage ? selection.image : undefined,
			})
				.then((challenge) => {
					setLoading(false);
					props.onSave && props.onSave(challenge.data);
				})
				.catch((e: string) => {
					setError(e);
					setLoading(false);
				});
		} else {
			setError("Debes completar todos los datos para continuar.");
		}
	};

	return (
		<Modal
			isVisible={props.isVisible}
			onClose={props.onClose}
			title={"Editar información"}
			error={error}
			buttonProps={{
				children: "Guardar",
				loading: loading,
				onClick: onSave,
			}}
		>
			<Row
				style={{
					alignItems: "center",
					justifyContent: "space-between",
				}}
			>
				<Image src={selection.image} />
				<Input
					type="image"
					maxHeight={1000}
					maxWidth={1000}
					label={
						props.challenge.image ? "Cambiar foto" : "Añadir foto"
					}
					onChange={(file: string) =>
						setSelection({
							...selection,
							image: file,
							changeImage: true,
						})
					}
				/>
			</Row>
			<Row>
				<Input
					containerStyle={{ flex: 1 }}
					type="number"
					design={"secondary"}
					placeholder="Objetivo (€)"
					defaultValue={props.challenge.goal}
					value={selection.goal}
					onChange={(e) =>
						setSelection({
							...selection,
							goal: parseInt(e.target.value),
						})
					}
				/>
			</Row>
			<Row>
				<Input
					containerStyle={{ flex: 1 }}
					type="text"
					design={"secondary"}
					placeholder="Título"
					defaultValue={props.challenge.title}
					value={selection.title}
					onChange={(e) =>
						setSelection({
							...selection,
							title: e.target.value,
						})
					}
				/>
			</Row>
			<TextArea
				style={{ height: 250, marginBottom: 32 }}
				placeholder="Descripción"
				type="default"
				design={"secondary"}
				defaultValue={props.challenge.history}
				onChange={(e) =>
					setSelection({
						...selection,
						history: e.target.value,
					})
				}
			/>
		</Modal>
	);
};
export default InfoModal;
export interface Props {
	isVisible: boolean;
	challenge: ChallengeScheme;
	onClose: () => void;
	onSave?: (challenge: ChallengeScheme) => void;
}

import { Action } from '../actions/index'
import { ActionType } from '../actions-types/index'
import moment from 'moment';

export const volunteerTableFilterReducer = (filter = { descending: 'createdAt' }, action:Action) =>{
    switch (action.type) {
    	case ActionType.SET_VOLUNTEER_TABLE_FILTERS:
       		return action.data;
      	default:
        	return filter;
    }
};

export const grandpaTableFilterReducer = (filter = { descending: 'createdAt' }, action:Action) =>{
    switch (action.type) {
    	case ActionType.SET_GRANDPA_TABLE_FILTERS:
       		return action.data;
      	default:
        	return filter;
    }
};

export const taskTableFilterReducer = (filter = { state: ['pending', 'inProgress'] }, action:Action) =>{
    switch (action.type) {
    	case ActionType.SET_TASK_TABLE_FILTERS:
       		return action.data;
      	default:
        	return filter;
    }
};

export const coorporateTableFilterReducer = (filter = {}, action:Action) =>{
    switch (action.type) {
    	case ActionType.SET_COORPORATE_TABLE_FILTERS:
       		return action.data;
      	default:
        	return filter;
    }
};

export const residenceTableFilterReducer = (filter = {}, action:Action) =>{
    switch (action.type) {
    	case ActionType.SET_RESIDENCE_TABLE_FILTERS:
       		return action.data;
      	default:
        	return filter;
    }
};

export const challengeTableFilterReducer = (filter = {}, action:Action) =>{
    switch (action.type) {
    	case ActionType.SET_CHALLENGE_TABLE_FILTERS:
       		return action.data;
      	default:
        	return filter;
    }
};

export const eventTableFilterReducer = (filter = {}, action:Action) =>{
    switch (action.type) {
    	case ActionType.SET_EVENT_TABLE_FILTERS:
       		return action.data;
      	default:
        	return filter;
    }
};

export const ambassadorTableFilterReducer = (filter = {}, action:Action) =>{
    switch (action.type) {
    	case ActionType.SET_AMBASSADOR_TABLE_FILTERS:
       		return action.data;
      	default:
        	return filter;
    }
};

export const salesTableFilterReducer = (filter = {
	createdAt: {
		min: moment().startOf('month').toDate(),
		max: moment().endOf('month').toDate(),
	},
	limit: 20,
	page: 0
}, action:Action) =>{
    switch (action.type) {
    	case ActionType.SET_SALES_TABLE_FILTERS:
       		return action.data;
      	default:
        	return filter;
    }
};
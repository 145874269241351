import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
	ErrorScheme,
	Product,
	ProductScheme,
	Ticket,
	TicketScheme,
	UserScheme,
} from "client-v2";
import { useSelector } from "react-redux";
import { State } from "../../../redux";

import Card from "../../../components/Containers/Card";
import { Text, Modal, Filter, Button } from "@adoptaunabuelo/react-components";
import TicketList from "../../../components/Profile/TicketList";

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 13px;
`;
const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

const SectionMain = (props: Props) => {
	const currentUser: UserScheme = useSelector(
		(state: State) => state.currentUser
	);

	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [modalError, setModalError] = useState<string | undefined>(undefined);
	const [selectedTicket, setSelectedTicket] = useState<
		TicketScheme | undefined
	>(undefined);
	const [tickets, setTickets] = useState<Array<TicketScheme>>([]);
	const [ticketStates, setTicketStates] = useState<Array<string>>(["active"]);
	const [ticketCount, setTicketCount] = useState(0);
	const [page, setPage] = useState(0);
	const [selectedOptions, setSelectedOptions] = useState<
		Array<{ id: string; [key: string]: any }>
	>([{ id: "active", label: "Activo" }]);

	useEffect(() => {
		getTickets(ticketStates, page);
	}, []);

	const handleFilterChange = (
		newSelectedOptions: Array<{ id: string; [key: string]: any }>
	) => {
		setSelectedOptions(newSelectedOptions);
		const temp = newSelectedOptions.map((i) => i.id);
		setTicketStates(temp);
		getTickets(temp, page);
	};

	const getTickets = async (ticketStates: Array<string>, newPage: number) => {
		setLoading(true);
		Product.getParticipants(props.product.objectId, {
			state: ticketStates,
			limit: 20,
			page: newPage,
		})
			.then((result) => {
				if (newPage > page) {
					setTickets([...tickets, ...result.data]);
					setPage(newPage);
				} else {
					setTickets(result.data);
					setPage(0);
				}
				setTicketCount(result.pagination.count);
				setLoading(false);
			})
			.catch((e: string) => {
				setLoading(false);
			});
	};

	const onSave = (data: { ticket: TicketScheme; action: string }) => {
		setSelectedTicket(data.ticket);
		if (data.action === "delete") {
			setShowModal(true);
		}
	};

	const onModalClick = () => {
		if (selectedTicket) {
			setLoading(true);
			Ticket.remove(selectedTicket.objectId, {
				adminId: currentUser.objectId,
			})
				.then(() => {
					setLoading(false);
					setShowModal(false);
					getTickets(ticketStates, 0);
				})
				.catch((error: ErrorScheme) => {
					setModalError(error.message);
					setLoading(false);
				});
		}
	};

	return (
		<Card style={{ marginBottom: 16 }}>
			<Modal
				isVisible={showModal}
				title={"¿Seguro que quieres continuar?"}
				subtitle="Vas a cancelar la entrada de este usuario/a y no podrá asistir al evento."
				error={modalError}
				shouldCloseOnOverlayClick={true}
				buttonProps={{
					children: "Si, cancelar entrada",
					loading: loading,
					onClick: onModalClick,
				}}
				onClose={() => setShowModal(false)}
			/>
			<TitleHeader>
				<Row>
					<Text type="p" weight={"medium"}>
						{ticketCount} inscritos
					</Text>

					<Filter
						id="filter-state"
						placeholder="Estado"
						type="single"
						shape="circle"
						position="bottom-left"
						selectedOptions={selectedOptions}
						options={[
							{
								id: "active",
								label: "Activo",
							},
							{
								id: "canceled",
								label: "Cancelado",
							},
						]}
						onChange={handleFilterChange}
					/>
				</Row>
			</TitleHeader>
			<TicketList data={tickets} onSave={onSave} />
			<Button
				design={"call-to-action"}
				onClick={() => {
					getTickets(ticketStates, page + 1);
				}}
			>
				Ver más
			</Button>
		</Card>
	);
};

export default SectionMain;
export interface Props {
	product: ProductScheme;
}

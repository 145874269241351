import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { Leads, Subscription, User, Payment } from "client-v2";
import { json2csv } from "json-2-csv";

import { ArrowLeft, Circle } from "lucide-react";
import {
	Modal,
	Text,
	ColorV2,
	Button,
	Avatar,
	Select,
} from "@adoptaunabuelo/react-components";
import LoadCSV, { LoadCSVRef } from "../LoadCSV/LoadCSV";
import moment from "moment";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
const Cell = styled.div`
	display: flex;
	flex-direction: row;
	gap: 6px;
	cursor: pointer;
	border-bottom: 1px solid ${ColorV2.border.neutralSoft};
	padding-bottom: 8px;
	&:hover {
		background-color: ${ColorV2.surface.neutralSoft};
	}
`;
const Column = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	gap: 8px;
`;
const TableStyled = styled.table`
	width: 100%;
	white-space: nowrap;
`;
const TableRow = styled.tr`
	height: 32px;
`;
const WarningView = styled.div`
	display: flex;
	flex-direction: column;
	padding: 12px;
	background-color: ${ColorV2.surface.redSoft};
	border-radius: 12px;
	margin-bottom: 8px;
`;

// Función para parsear una línea
function parseFileContent(content: string) {
	// Dividir el contenido en líneas
	const lines = content.split("\n").filter((line) => line.trim() !== ""); // Quitar líneas vacías

	// Ignorar las dos primeras líneas y procesar las demás
	const relevantLines = lines.slice(2);

	// Dividir cada línea en campos separados por espacios
	const parsedData = relevantLines.map((line) => {
		return line.split(/\s+/).filter((field) => field.trim() !== ""); // Quitar espacios extra
	});

	return parsedData;
}

const UploadLeadsModal = (props: Props) => {
	const loadCSV = useRef<LoadCSVRef>(null);
	const [step, setStep] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [selectedOption, setSelectedOption] = useState<any>(undefined);
	const [resultErrors, setResultErrors] = useState<Array<any> | undefined>(
		undefined
	);
	const [resultSuccess, setResultSuccess] = useState<Array<any> | undefined>(
		undefined
	);
	const [showErrors, setShowErrors] = useState(false);
	const [keys, setKeys] = useState<Array<any>>([]);
	const [admins, setAdmins] = useState<Array<{ id: string; label: string }>>(
		[]
	);
	const [selectedAdmin, setSelectedAdmin] = useState<string | undefined>(
		undefined
	);
	const [fileContent, setFileContent] = useState("");
	const [paidMoney, setPaidMoney] = useState(0);
	const [cancelMoney, setCancelMoney] = useState(0);
	const [remittanceName, setRemittanceName] = useState([""]);
	const [remittanceCount, setRemittanceCount] = useState(0);
	const [ibanChanged, setIbanChanged] = useState(0);
	const [ibanFailed, setIbanFailed] = useState(0);

	const menuOption = [
		{
			id: "leads",
			title: "👥 Nuevas altas",
			subtitle:
				"Carga el listado de nuevos socios generados por proveedores. Se generarán las suscripciones de manera automática.",
		},
		{
			id: "update-iban",
			title: "🔄 Actualizar IBAN",
			subtitle:
				"Carga el listado de subscripciones con el nuevo IBAN y se actualizará en todas las paycards.",
		},
		{
			id: "payment-errors",
			title: "❌💸 Fallos de cobro",
			subtitle: "Carga el listado de cobros fallidos.",
		},
		{
			id: "call",
			title: "❌📞 Bajas de llamada",
			subtitle:
				"Carga el listado de personas que no quieren ser contactados de nuevo.",
		},
	];

	useEffect(() => {
		if (props.isVisible) {
			setStep(0);
			setError("");
			setResultErrors(undefined);
			setResultSuccess(undefined);
			setLoading(false);
			setShowErrors(false);
			setAdmins([]);
			setSelectedAdmin(undefined);
			setFileContent("");
			setPaidMoney(0);
			setCancelMoney(0);
			setRemittanceName([""]);
			setRemittanceCount(0);
		}
	}, [props.isVisible]);

	const getAdmins = () => {
		setLoading(true);
		User.get({
			type: "admin",
			data: {
				department: ["sales-external"],
			},
			active: true,
		}).then((result) => {
			const temp = result.data.map((item) => {
				return {
					id: item.objectId,
					label: item.name + " " + item.surname,
					icon: (
						<Avatar
							style={{ height: 24, width: 24, fontSize: 14 }}
							name={item.name}
							icon={item.image?.url}
						/>
					),
				};
			});
			setAdmins(temp);
			setLoading(false);
		});
	};

	const onOptionChange = (op: any) => {
		setSelectedOption(op);
		if (op.id === "leads") {
			setKeys([
				{
					id: "user",
					title: "Datos de usuario",
					data: [
						{
							id: "name",
							title: "Nombre",
							needed: true,
						},
						{
							id: "surname",
							title: "Apellidos",
							needed: true,
						},
						{
							id: "objectId",
							title: "Id del usuario",
							needed: true,
						},
						{
							id: "idCard",
							title: "NIF",
						},
						{
							id: "phone",
							title: "Teléfono",
							type: "phone",
						},
						{
							id: "email",
							title: "Email",
						},
						{
							id: "address",
							title: "Dirección",
						},
						{
							id: "city",
							title: "Ciudad",
						},
						{
							id: "zipCode",
							title: "Código postal",
						},
						{
							id: "birthday",
							title: "Cumpleaños",
							type: "date",
						},
					],
				},
				{
					id: "subscription",
					title: "Datos de facturación",
					data: [
						{
							id: "IBAN",
							title: "IBAN",
							needed: true,
						},
						{
							id: "price",
							title: "Cuota (€)",
							needed: true,
							type: "number",
						},
						{
							id: "charge",
							title: "Periodicidad",
							needed: true,
						},
						{
							id: "endDate",
							title: "Fecha primer cobro",
							needed: true,
							type: "date",
						},
					],
				},
			]);
		} else if (op.id === "payment-errors") {
			setKeys([
				{
					id: "user",
					title: "Datos de usuario",
					data: [
						{
							id: "objectId",
							title: "Id del usuario",
							needed: true,
						},
					],
				},
			]);
		} else if (op.id === "call-email") {
			setKeys([
				{
					id: "user",
					title: "Datos de usuario",
					data: [
						{
							id: "objectId",
							title: "Id del usuario",
							needed: true,
						},
					],
				},
			]);
		} else if (op.id === "call") {
			setKeys([
				{
					id: "user",
					title: "Datos de usuario",
					data: [
						{
							id: "objectId",
							title: "Id del usuario",
							needed: true,
						},
					],
				},
			]);
		} else if (op.id === "email") {
			setKeys([
				{
					id: "user",
					title: "Datos de usuario",
					data: [
						{
							id: "objectId",
							title: "Id del usuario",
							needed: true,
						},
					],
				},
			]);
		}
	};

	const onBackClick = () => {
		setError("");
		setFileContent("");

		if (selectedOption.id === "leads") {
			setStep(step - 1);
		} else {
			setStep(0);
		}
	};

	const onNextClick = async () => {
		if (step === 0) {
			if (selectedOption.id === "leads") {
				setStep(step + 1);
				getAdmins();
			} else {
				setStep(step + 2);
			}
		} else if (step === 1) {
			if (selectedAdmin) setStep(step + 1);
			else
				setError(
					"Debes seleccionar a quién se deben asignar las altas antes de continuar."
				);
		} else if (step === 2) {
			const data = loadCSV.current?.getData();
			if (selectedOption.id === "leads") {
				if (data?.error) {
					setError(data.error);
				} else {
					if (data?.result?.errors && data.result.errors.length > 0) {
						setError(
							"Existen filas con errores que debes solucionar antes de continuar"
						);
					} else if (data?.result?.success) {
						setError("");
						if (selectedOption.id === "leads") {
							loadNewSubscriptions(data.result.success);
						} else if (selectedOption.id === "call") {
							loadCallCancellation(data.result.success);
						}
					}
				}
			} else if (selectedOption.id === "payment-errors") {
				if (fileContent) {
					loadPaymentErrors(fileContent);
				} else {
					setError(
						"Debes seleccionar un archivo XML antes de continuar"
					);
				}
			} else if (selectedOption.id === "update-iban") {
				if (fileContent) {
					updateIban(fileContent);
				} else {
					setError(
						"Debes seleccionar un archivo TXT antes de continuar"
					);
				}
			}
		} else {
			props.onClose();
		}
	};

	const loadNewSubscriptions = (csvData: Array<any>) => {
		if (selectedAdmin) {
			setLoading(true);
			Subscription.load({
				adminId: selectedAdmin,
				data: csvData,
			})
				.then((result) => {
					setResultSuccess([
						...(result.data.subscriptions
							? result.data.subscriptions
							: []),
						...(result.data.payments ? result.data.payments : []),
					]);
					setResultErrors(result.data.errors);

					//Download the errors
					loadCSV.current?.downloadErrors(result.data.errors);
					setStep(step + 1);
					setLoading(false);
				})
				.catch((e: string) => {
					setLoading(false);
					setError(e);
				});
		}
	};

	const loadPaymentErrors = (fileContent: string) => {
		setLoading(true);
		Payment.updateRemittance({
			xml: fileContent,
			//hidden: true
		})
			.then((result) => {
				const {
					paidCount,
					paidMoney,
					cancelUsers,
					cancelMoney,
					totalCount,
					remittancesId,
				} = result.data;
				setResultSuccess([paidCount]);
				setResultErrors(cancelUsers);
				setPaidMoney(paidMoney);
				setCancelMoney(cancelMoney);
				setRemittanceName(remittancesId);
				setRemittanceCount(totalCount);
				if (cancelUsers.length > 0) {
					downloadCSV(
						cancelUsers,
						`remittance_errors_${moment().format(
							"YYYY-MM-DD HH:mm:ss"
						)}.csv`
					);
				}
				setStep(step + 1);
				setLoading(false);
			})
			.catch((e: string) => {
				setLoading(false);
				setError(e);
			});
	};

	const loadCallCancellation = (csvData: Array<any>) => {
		setLoading(true);
		//Get user ids
		const users = csvData.map((data) => data.user.objectId);
		//Set leads as not valid leads
		Leads.set({
			users: users,
			action: "not-valid",
		})
			.then(({ errors, data }) => {
				setResultErrors(errors);
				setResultSuccess(data);

				//Download the errors
				loadCSV.current?.downloadErrors(
					errors.map((i) => ({ user: { objectId: i } }))
				);
				setStep(step + 1);
				setLoading(false);
			})
			.catch((e: string) => {
				setLoading(false);
				setError(e);
			});
	};

	const downloadCSV = (data: any, filename: string) => {
		//Generate CSV
		const csv = json2csv(data, {
			emptyFieldValue: "",
		});

		//Download file
		const url = window.URL.createObjectURL(new Blob([csv]));
		const link = document.createElement("a");
		link.href = url;
		const fileName = filename;
		link.setAttribute("download", fileName);
		document.body.appendChild(link);
		link.click();
		link.remove();
	};

	const handleFileChange = (event: any) => {
		const file = event.target.files?.[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				setFileContent(e.target?.result as string);
			};
			reader.readAsText(file);
			setError("");
		}
	};

	const updateIban = (fileContent: string) => {
		const parsedData = parseFileContent(fileContent);
		const dataToSend = parsedData.reduce(
			(acc: string[][], line: string[]) => {
				if (line.length === 4) {
					acc.push([line[1], line[2].slice(11)]);
				}
				return acc;
			},
			[]
		);
		Payment.updateIban(dataToSend)
			.then((result: any) => {
				setIbanChanged(result.totalChanged);
				setIbanFailed(result.totalFailed);
				setStep(step + 1);
				setLoading(false);
			})
			.catch((e: string) => {
				setLoading(false);
				setError(e);
			});
	};

	return (
		<Modal
			isVisible={props.isVisible}
			title={
				step === 0
					? "Carga"
					: step === 1
					? "Asignar carga"
					: step === 2
					? "Edita el archivo"
					: "Resultado"
			}
			subtitle={
				step === 0
					? "Selecciona el tipo de archivo que vas a cargar"
					: step === 1
					? "Indica a qué empreasa o persona se debe asignar esta acción"
					: step === 2
					? "Carga el archivo desde tu ordenador y relaciona las columnas del archivo con sus variables correspondientes."
					: "Comprueba los datos que han sido cargados de manera correcta y los errores."
			}
			buttonProps={{
				children: step === 3 ? "Finalizar" : "Siguiente",
				loading: loading,
				disabled: selectedOption ? false : true,
				onClick: onNextClick,
			}}
			error={error}
			Bottom={
				<Row>
					<Button
						design={"image"}
						disabled={step === 0}
						icon={<ArrowLeft />}
						onClick={onBackClick}
					/>
				</Row>
			}
			onClose={props.onClose}
		>
			{step === 0 && (
				<Container>
					{menuOption.map((item, index) => {
						const selected = selectedOption
							? selectedOption.id === item.id
							: false;
						return (
							<Cell
								key={"menu-option-" + index}
								onClick={() => onOptionChange(item)}
							>
								<Circle
									height={20}
									width={20}
									color={
										selected
											? "transparent"
											: ColorV2.text.neutralMedium
									}
									fill={
										selected
											? ColorV2.text.primary
											: "transparent"
									}
								/>
								<Column>
									<Row>
										<Text type="p2" weight="medium">
											{item.title}
										</Text>
									</Row>
									<Text
										type="c1"
										style={{
											color: ColorV2.text.neutralMedium,
										}}
									>
										{item.subtitle}
									</Text>
								</Column>
							</Cell>
						);
					})}
				</Container>
			)}
			{step === 1 && (
				<Container style={{ paddingBottom: 32 }}>
					<Select
						id="admin-select"
						optionStyle={{ width: 250 }}
						title="admin"
						options={admins}
						onChange={(op) => {
							setError("");
							setSelectedAdmin(op.id);
						}}
					/>
				</Container>
			)}
			{step === 2 && (
				<>
					{selectedOption.id === "payment-errors" && (
						<input
							type="file"
							accept=".xml"
							onChange={handleFileChange}
						/>
					)}
					{selectedOption.id === "update-iban" && (
						<input
							type="file"
							accept=".txt"
							onChange={handleFileChange}
						/>
					)}
					{selectedOption.id !== "payment-errors" &&
						selectedOption.id !== "update-iban" && (
							<>
								<LoadCSV ref={loadCSV} keys={keys} />
								{error && (
									<Text
										type="p2"
										style={{ color: ColorV2.text.red }}
									>
										{error}
									</Text>
								)}
							</>
						)}
				</>
			)}
			{step === 3 && (
				<>
					{selectedOption.id === "payment-errors" && (
						<Container style={{ marginBottom: 32 }}>
							<Row>
								<Text type="p">Nombre de la/s Remesa/s:</Text>
							</Row>
							<Container style={{ marginLeft: 16 }}>
								{remittanceName.map((item) => (
									<Row>
										<Text type="p" key={item}>
											{item}
										</Text>
									</Row>
								))}
							</Container>
							<Row>
								<Text type="p">
									Numero de recibos cobrados:{" "}
									<span
										style={{
											color: ColorV2.text.green,
											fontWeight: 600,
										}}
									>
										{resultSuccess}
									</span>{" "}
									/{" "}
									<span
										style={{
											color: ColorV2.text.neutralHard,
											fontWeight: 600,
										}}
									>
										{remittanceCount}
									</span>
								</Text>
							</Row>
							<Row>
								<Text type="p">
									Dinero cobrado:{" "}
									<span
										style={{
											color: ColorV2.text.green,
											fontWeight: 600,
										}}
									>
										{paidMoney}€
									</span>{" "}
									/{" "}
									<span
										style={{
											color: ColorV2.text.neutralHard,
											fontWeight: 600,
										}}
									>
										{paidMoney + cancelMoney}€
									</span>
								</Text>
							</Row>
							{resultErrors && resultErrors.length > 0 && (
								<>
									<Text type="p" style={{ marginTop: 16 }}>
										Falladas
									</Text>
									<TableStyled>
										<tr>
											<th
												style={{
													textAlign: "left",
													borderBottom:
														"2px solid" +
														ColorV2.border
															.neutralMedium,
												}}
											>
												<Text
													type="p2"
													weight="semibold"
												>
													userId
												</Text>
											</th>
											<th
												style={{
													textAlign: "left",
													borderBottom:
														"2px solid" +
														ColorV2.border
															.neutralMedium,
												}}
											>
												<Text
													type="p2"
													weight="semibold"
												>
													errorCode
												</Text>
											</th>
										</tr>
										<tbody>
											{resultErrors?.map((item) => (
												<TableRow>
													<td
														style={{
															borderBottom:
																"1px solid " +
																ColorV2.border
																	.neutralSoft,
														}}
													>
														<Text type="c1">
															{item.user}
														</Text>
													</td>
													<td
														style={{
															borderBottom:
																"1px solid " +
																ColorV2.border
																	.neutralSoft,
														}}
													>
														<Text type="c1">
															{item.errorCode}
														</Text>
													</td>
												</TableRow>
											))}
										</tbody>
									</TableStyled>
									<WarningView>
										<Text type="p" weight="semibold">
											🚨 Usuarios cuya transaccion ha
											fallado
										</Text>
										<Text type="p2">
											Se ha descargado automáticamente un
											documento con las transacciones
											fallidas y el motivo de este fallo.
										</Text>
									</WarningView>
								</>
							)}
						</Container>
					)}
					{selectedOption.id === "update-iban" &&
						selectedOption.id !== "payment-errors" && (
							<Container style={{ marginBottom: 32 }}>
								<Row>
									<Text type="p">IBAN actualizados: </Text>
								</Row>
								<Container style={{ marginLeft: 16 }}>
									<Row>
										<Text type="p">
											IBAN cambiados:{" "}
											<span
												style={{
													color: ColorV2.text.green,
													fontWeight: 600,
												}}
											>
												{ibanChanged}
											</span>{" "}
										</Text>
									</Row>
									<Row>
										<Text type="p">
											IBAN fallados:{" "}
											<span
												style={{
													color: ColorV2.text.red,
													fontWeight: 600,
												}}
											>
												{ibanFailed}
											</span>{" "}
										</Text>
									</Row>
								</Container>
							</Container>
						)}
					{selectedOption.id !== "payment-errors" &&
						selectedOption.id !== "update-iban" && (
							<Container style={{ marginBottom: 32 }}>
								{resultErrors && resultErrors.length > 0 && (
									<WarningView>
										<Text type="p" weight="semibold">
											🚨 Descarga de errores
										</Text>
										<Text type="p2">
											Parece que hubo algún error con
											alguno de los elementos. Se ha
											descargado automáticamente un
											documento con los elementos
											fallidos.{" "}
											<span style={{ fontWeight: 600 }}>
												Inténtalo de nuevo SOLO con los
												elementos erroneos
											</span>
											.
										</Text>
									</WarningView>
								)}
								<Row>
									<Text type="p">
										Filas correctas:{" "}
										<span
											style={{
												color: ColorV2.text.green,
												fontWeight: 600,
											}}
										>
											{resultSuccess?.length}
										</span>
									</Text>
								</Row>
								<Row>
									<Text type="p">
										Filas con errores:{" "}
										<span
											style={{
												color: ColorV2.text.red,
												fontWeight: 600,
											}}
										>
											{resultErrors?.length}
										</span>
									</Text>
									{resultErrors &&
										resultErrors.length > 0 && (
											<Button
												design={"call-to-action"}
												size="small"
												onClick={() =>
													setShowErrors(!showErrors)
												}
											>
												{showErrors
													? "Ocultar errores"
													: "Ver errores"}
											</Button>
										)}
								</Row>
								{showErrors && resultErrors && (
									<TableStyled>
										<tr>
											<th
												style={{
													textAlign: "left",
													borderBottom:
														"2px solid" +
														ColorV2.border
															.neutralMedium,
												}}
											>
												<Text
													type="p2"
													weight="semibold"
												>
													userId
												</Text>
											</th>
											<th
												style={{
													textAlign: "left",
													borderBottom:
														"2px solid" +
														ColorV2.border
															.neutralMedium,
												}}
											>
												<Text
													type="p2"
													weight="semibold"
												>
													error
												</Text>
											</th>
										</tr>
										<tbody>
											{resultErrors.map((item) => (
												<TableRow>
													<td
														style={{
															borderBottom:
																"1px solid " +
																ColorV2.border
																	.neutralSoft,
														}}
													>
														<Text type="c1">
															{item.user.objectId}
														</Text>
													</td>
													<td
														style={{
															borderBottom:
																"1px solid " +
																ColorV2.border
																	.neutralSoft,
														}}
													>
														<Text type="c1">
															{item.error}
														</Text>
													</td>
												</TableRow>
											))}
										</tbody>
									</TableStyled>
								)}
							</Container>
						)}
				</>
			)}
		</Modal>
	);
};
export default UploadLeadsModal;
export interface Props {
	isVisible: boolean;
	onClose: () => void;
}

import React, { ChangeEvent, useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { Event, Product, EventScheme, ProductScheme } from "client-v2";

import { Input, Modal } from "@adoptaunabuelo/react-components";

const Row = styled.div`
	display: flex;
	flex-direction: row;
	gap: 18px;
	margin-bottom: 18px;
`;

const DetailModal = (props: Props) => {
	const [loading, setLoading] = useState(false);
	const [selectedAddress, setSelectedAddress] = useState({
		address: props.event.address,
		shortAddress: props.event.shortAddress,
		location: props.event.location
			? {
					lat: props.event.location.latitude,
					lng: props.event.location.longitude,
			  }
			: undefined,
	});
	const [startDate, setStartDate] = useState(
		moment(props.product.startDate?.iso).format("YYYY/MM/DD")
	);
	const [startHour, setStartHour] = useState(
		moment(props.product.startDate?.iso).format("HH:mm")
	);
	const [endDate, setEndDate] = useState(
		moment(props.product.endDate?.iso).format("YYYY/MM/DD")
	);
	const [endHour, setEndHour] = useState(
		moment(props.product.endDate?.iso).format("HH:mm")
	);
	const [error, setError] = useState<string | undefined>(undefined);

	const onSave = async () => {
		const start = moment(startDate + " " + startHour);
		const end = moment(endDate + " " + endHour);
		if (
			start &&
			end &&
			selectedAddress.address &&
			selectedAddress.location &&
			selectedAddress.shortAddress
		) {
			setLoading(true);
			const promise = await Promise.all([
				Product.set(props.product.objectId, {
					startDate: start.toDate(),
					endDate: end.toDate(),
				}),
				Event.set(props.event.objectId, {
					address: selectedAddress.address,
					shortAddress: selectedAddress.shortAddress,
					location: {
						latitude: selectedAddress.location.lat,
						longitude: selectedAddress.location.lng,
					},
				}),
			]);
			setLoading(false);
			props.onSave &&
				props.onSave({
					product: promise[0].data,
					event: promise[1].data,
				});
		} else {
			setError("Debes completar todos los campos");
		}
	};

	const onLocationChange = async (result: {
		address: string;
		geocoder: google.maps.GeocoderResult;
		location: google.maps.LatLngLiteral;
	}) => {
		let concatAddres = "";
		for (let name of result.geocoder.address_components) {
			concatAddres +=
				concatAddres !== "" ? "," + name.long_name : name.long_name;
		}
		concatAddres += ".";

		setSelectedAddress({
			...selectedAddress,
			address: result.address,
			location: { lat: result.location.lat, lng: result.location.lng },
		});
	};

	const handleDateChange = (
		event: ChangeEvent<HTMLInputElement> | Date,
		dateType: "start" | "end"
	) => {
		let dateValue: string | Date;

		if (event instanceof Date) {
			dateValue = event;
		} else if ("target" in event && event.target.value) {
			dateValue = event.target.value;
		} else {
			console.warn(
				"Advertencia: No se pudo obtener el valor de la fecha."
			);
			return;
		}

		const momentDate = moment(dateValue, moment.ISO_8601, true).isValid()
			? moment(dateValue)
			: moment(dateValue, "YYYY-MM-DD");

		if (momentDate.isValid()) {
			if (dateType === "start") {
				setStartDate(momentDate.format("YYYY-MM-DD"));
			} else if (dateType === "end") {
				setEndDate(momentDate.format("YYYY-MM-DD"));
			}
		}
	};

	return (
		<Modal
			isVisible={props.isVisible}
			onClose={props.onClose}
			title={"Editar información"}
			error={error}
			shouldCloseOnOverlayClick={true}
			buttonProps={{
				children: "Guardar",
				loading: loading,
				onClick: onSave,
			}}
		>
			<Row>
				<Input
					containerStyle={{ flex: 1 }}
					type="text"
					design={"secondary"}
					placeholder="Dirección aproximada (ej. Residencia)"
					value={selectedAddress.shortAddress}
					onChange={(e) =>
						setSelectedAddress({
							...selectedAddress,
							shortAddress: e.target.value,
						})
					}
				/>
			</Row>
			<Row>
				<Input
					type="location"
					design="secondary"
					placeholder="Dirección exacta (calle, nº, etc)"
					onLocationChange={onLocationChange}
				/>
			</Row>
			<Row style={{ marginTop: 18 }}>
				<Input
					type="date"
					design={"secondary"}
					placeholder="Fecha de inicio"
					hideCalendar={false}
					containerStyle={{ flex: 1 }}
					value={moment(startDate).format("YYYY-MM-DD")}
					onChange={(e: any) => handleDateChange(e, "start")}
				/>
				<Input
					type="time"
					design={"secondary"}
					style={{ width: 150 }}
					placeholder="Hora de inicio"
					defaultValue={moment(startHour).format("HH:mm")}
					value={startHour}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						setStartHour(e.target.value)
					}
				/>
			</Row>
			<Row>
				<Input
					type="date"
					design={"secondary"}
					placeholder="Fecha de fin"
					hideCalendar={false}
					containerStyle={{ flex: 1 }}
					value={moment(endDate).format("YYYY-MM-DD")}
					onChange={(e: any) => handleDateChange(e, "end")}
				/>
				<Input
					type="time"
					design={"secondary"}
					style={{ width: 150 }}
					placeholder="Hora de fin"
					defaultValue={moment(endHour).format("HH:mm")}
					value={endHour}
					onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
						setEndHour(e.target.value)
					}
				/>
			</Row>
		</Modal>
	);
};
export default DetailModal;
export interface Props {
	isVisible: boolean;
	event: EventScheme;
	product: ProductScheme;
	onClose: () => void;
	onSave?: (data: { product: ProductScheme; event: EventScheme }) => void;
}

import React, { CSSProperties, ReactElement, useEffect, useState } from "react";
import styled from "styled-components";
import { Regex } from "client-v2";
import { Player } from "@lottiefiles/react-lottie-player";
import moment from "moment";
import { useSelector } from "react-redux";
import { State } from "../../redux";

import animation from "../../assets/animation/button-loading.json";
import { Search, ExternalLink, Euro, RefreshCcw } from "lucide-react";
import {
	Text,
	SearchBar,
	Color,
	Button,
	Label,
	Avatar,
	Filter,
	Pagination,
	SwitchTag,
	ColorV2,
} from "@adoptaunabuelo/react-components";
import MapView from "./Map";
import Columns from "./Columns";

const Container = styled.div`
	display: flex;
	height: 100vh;
	overflow: hidden;
	flex-direction: column;
	padding: 16px 32px;
	width: -webkit-fill-available;
`;
const TitleContainer = styled.div`
	display: flex;
	flex: 1;
	margin-bottom: 16px;
	align-items: center;
`;
const TableContainer = styled.div`
	position: relative;
	height: 100%;
	overflow: auto;
	border-radius: 12px;
	background-color: ${ColorV2.surface.invert};
`;
const PageContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 16px;
	border-radius: 12px;
`;
const PageViewLeft = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
`;
const PageViewCenter = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
`;
const PageViewRight = styled.div`
	display: flex;
	flex: 1;
	justify-content: flex-end;
	align-items: center;
`;
const FiltersContainer = styled.div`
	display: flex;
	align-items: center;
	height: 64px;
	padding: 0px 12px;
	position: sticky;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1000;
	background-color: ${ColorV2.surface.invert};
`;
const FiltersView = styled.div`
	display: flex;
	flex: 1;
`;
const TableStyled = styled.table`
	width: 100%;
	white-space: nowrap;
`;
const TableHeader = styled.tr`
	position: sticky;
	top: 64px;
	height: 40px;
	z-index: 100;
	background-color: ${ColorV2.surface.invert};
`;
const TableRow = styled.tr<{ isReminder: boolean }>`
	height: 48px;
	cursor: pointer;
	background-color: ${(props) =>
		props.isReminder ? Color.background.secondaryLow : "transparent"};
	&:hover {
		background-color: ${Color.status.neutral.hover};
	}
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
`;
const Column = styled.div`
	display: flex;
	flex-direction: column;
`;
const EmptyView = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
	align-items: center;
	justify-content: center;
	height: 100%;
`;
const LoadingView = styled.div`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(0, 0, 0, 0.7);
	z-index: 10000;
`;
const TypeView = styled.div`
	display: flex;
	flex-direction: row;
`;
const CrownView = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 24px;
	width: 24px;
	border-radius: 40px;
	background-color: ${Color.status.color.warning};
`;

const Table = (props: Props) => {
	const role = useSelector((state: State) => state.role);
	const [timer, setTimer] = useState<any>(undefined);
	const [selectedFilters, setSelectedFilters] = useState<any>({});
	const [selectedOption, setSelectedOption] = useState("list");
	const [address, setAddress] = useState<undefined | string>(undefined);

	useEffect(() => {
		let temp = {};
		props.filters.map(
			(item) =>
				(temp = {
					...temp,
					[item.id]: item.selectedOptions
						? item.selectedOptions
						: undefined,
				})
		);
		setSelectedFilters({
			...temp,
			limit: 20,
		});
	}, []);

	const onFilterChange = (filter: any, options: any) => {
		if (filter.type === "date") {
			const temp = {
				...selectedFilters,
				[filter.id]: options,
				limit: selectedOption === "map" ? 100 : 20,
				page: 0,
			};
			setSelectedFilters(temp);
			props.onFiltersChange && props.onFiltersChange(temp);
		} else {
			const array = options.map((item: any) => item.id);
			const temp = {
				...selectedFilters,
				[filter.id]: filter.type === "multiple" ? array : array[0],
				limit: selectedOption === "map" ? 100 : 20,
				page: 0,
			};
			setSelectedFilters(temp);
			props.onFiltersChange && props.onFiltersChange(temp);
		}
	};

	const onRowClick = (row: any, target?: string) => {
		props.onRowClick && props.onRowClick(row, target);
	};

	const onPageChange = (page: number) => {
		const temp = {
			...selectedFilters,
			page: page,
		};
		setSelectedFilters(temp);
		props.onFiltersChange && props.onFiltersChange(temp);
	};

	const onOptionChange = (option: string) => {
		setSelectedOption(option);
		const filters = {
			...selectedFilters,
			limit: option === "map" ? 100 : 20,
			location:
				option === "list"
					? undefined
					: selectedFilters.location
					? selectedFilters.location
					: undefined,
			exists:
				option === "map"
					? selectedFilters.location
						? undefined
						: ["location"]
					: undefined,
			showData: option === "data" ? true : undefined,
			priority: option === "hot" ? "hot" : undefined,
		};
		setSelectedFilters(filters);
		props.onFiltersChange && props.onFiltersChange(filters);
	};

	const onSearchBarChange = (e: any) => {
		if (timer) {
			clearTimeout(timer);
			setTimer(undefined);
		}
		setTimer(
			setTimeout(() => {
				const text = e.target.value;
				if (text && text.length > 0) {
					let newFilter = {};
					const regxPhone = /^[0-9\+]{1,}[0-9\-]{1,15}$/;
					const text2 = text.replace(/\s/g, "");
					if (Regex.email(text2))
						newFilter = {
							email: text2,
							name: undefined,
							phone: undefined,
							objectId: undefined,
						};
					else if (regxPhone.test(text2))
						newFilter = {
							phone: text2,
							email: undefined,
							name: undefined,
							objectId: undefined,
						};
					else if (Regex.objectId(text2))
						newFilter = {
							phone: undefined,
							name: undefined,
							email: undefined,
							objectId: text2,
						};
					else if (!text.includes("+"))
						newFilter = {
							name: text,
							phone: undefined,
							email: undefined,
							objectId: undefined,
						};
					const temp = {
						...selectedFilters,
						...newFilter,
					};
					setSelectedFilters(temp);
					props.onFiltersChange && props.onFiltersChange(temp);
				} else {
					const temp = {
						...selectedFilters,
						name: undefined,
						email: undefined,
						phone: undefined,
					};
					setSelectedFilters(temp);
					props.onFiltersChange && props.onFiltersChange(temp);
				}
			}, 500)
		);
	};

	const onLocationChange = (location: {
		lat: number;
		lng: number;
		km: number;
	}) => {
		const temp = {
			...selectedFilters,
			location: {
				lat: location.lat,
				lng: location.lng,
				km: location.km,
			},
			exists: undefined,
		};
		setSelectedFilters(temp);
		props.onFiltersChange && props.onFiltersChange(temp);
	};

	return (
		<Container>
			<TitleContainer>
				<PageViewLeft style={{ flex: 0 }}>
					<Text type="h1" style={{ fontWeight: 600, fontSize: 32 }}>
						{props.title}
					</Text>
					{selectedOption === "list" && props.filters.length > 0 && (
						<SearchBar
							style={{ marginLeft: 24 }}
							placeholder="Buscar"
							defaultValue={selectedFilters.name}
							onChange={onSearchBarChange}
						/>
					)}
				</PageViewLeft>
				<PageViewRight>
					{props.switchOptions && (
						<SwitchTag
							style={{ height: 36 }}
							options={props.switchOptions}
							onChange={(option) => onOptionChange(option.id)}
						/>
					)}
					{props.ButtonView && props.ButtonView()}
				</PageViewRight>
			</TitleContainer>
			{props.loading && (
				<LoadingView>
					<Player
						style={{ height: 150, width: 150 }}
						loop={true}
						autoplay={true}
						src={animation}
					/>
				</LoadingView>
			)}
			<TableContainer>
				<FiltersContainer>
					<FiltersView>
						{props.filters.map((item) => {
							if (item.type === "ratio") {
								return (
									<Filter
										key={"filter" + item.id}
										style={{ marginRight: 4 }}
										onChange={(options: any) => {
											onFilterChange(item, options);
											if (item.id === "city") {
												setAddress(options[0].label);
											}
										}}
										min={0}
										max={100}
										{...item}
										selectedOptions={undefined}
										type="ratio"
									/>
								);
							} else if (item.type === "date") {
								return (
									<Filter
										key={"filter" + item.id}
										style={{ marginRight: 4 }}
										onChange={(options: any) => {
											onFilterChange(item, options);
										}}
										{...item}
										type="date"
										selectedOptions={undefined}
									/>
								);
							} else {
								return (
									<Filter
										key={"filter" + item.id}
										style={{ marginRight: 4 }}
										onChange={(options: any) => {
											onFilterChange(item, options);
											if (item.id === "city") {
												if(options.length > 0){
													setAddress(options[0].id);
												}else{
													setAddress(undefined);
												}
											}
										}}
										{...item}
										options={
											item.options ? item.options : []
										}
										type={item.type}
										selectedOptions={
											typeof item.selectedOptions ===
											"string"
												? [{ id: item.selectedOptions }]
												: Array.isArray(
														item.selectedOptions
												  )
												? item.selectedOptions.map(
														(value) => ({
															id: value,
														})
												  )
												: typeof item.selectedOptions ===
												  "object"
												? item.selectedOptions
												: []
										}
									/>
								);
							}
						})}
					</FiltersView>
				</FiltersContainer>
				{selectedOption === "list" ||
				selectedOption === "data" ||
				selectedOption === "hot" ? (
					props.rows.length === 0 ? (
						<EmptyView>
							<Search
								style={{ marginRight: 16 }}
								height={50}
								width={50}
							/>
							<Column>
								<Text type="h4">
									No hay resultados para tu búsqueda
								</Text>
								<Text
									type="p"
									style={{ color: Color.text.high }}
								>
									Intenta modificando los filtros o términos
									de búsqueda
								</Text>
							</Column>
						</EmptyView>
					) : (
						<TableStyled cellSpacing={0}>
							<tbody>
								<TableHeader>
									{role.actions.newTab && (
										<th
											style={{
												textAlign: "left",
												padding: "8px 16px",
												borderBottom:
													"2px solid" +
													Color.line.soft,
											}}
											key={"th-external-link"}
										></th>
									)}
									{props.columns.map((item, index) => (
										<th
											style={{
												textAlign: "left",
												padding: "8px 16px",
												borderBottom:
													"2px solid" +
													Color.line.soft,
											}}
											key={item.id + index}
										>
											<Text
												type="p"
												style={{ fontWeight: 500 }}
											>
												{item.title}
											</Text>
										</th>
									))}
								</TableHeader>
								{props.rows.map((row: any, index) => (
									<TableRow
										style={{
											cursor: props.disabled
												? "default"
												: "pointer",
										}}
										key={"row" + index}
										isReminder={row.isReminder}
										onClick={() => {
											if (!props.disabled)
												onRowClick(row);
										}}
									>
										{role.actions.newTab && (
											<td
												style={{
													width: 35,
													borderBottom:
														"1px solid " +
														Color.line.soft,
													padding: "0px 4px",
												}}
												key={"td-external-link"}
											>
												<Button
													design="image"
													icon={
														<ExternalLink
															height={20}
															width={20}
															color={
																Color.text.high
															}
														/>
													}
													onClick={(e) => {
														onRowClick(
															row,
															"_blank"
														);
														e.stopPropagation();
													}}
												/>
											</td>
										)}
										{props.columns.map((column, index) => (
											<td
												style={{
													borderBottom:
														"1px solid " +
														Color.line.soft,
													padding: "0px 12px",
												}}
												key={"td" + index}
											>
												{column.id === "state" ? (
													<Label
														text={row[column.id]}
													/>
												) : column.id ===
														"Subscription.state" &&
												  row.Subscription &&
												  row.Subscription.state ? (
													<Label
														text={
															row.Subscription
																.state
														}
													/>
												) : column.id ===
												  "residenceState" ? (
													<Label
														text={
															row.state ===
															"active"
																? "Activa"
																: row.state ===
																  "completed"
																? "Completa"
																: "Inactiva"
														}
														color={
															row.state ===
															"active"
																? "#2D55B5"
																: row.state ===
																  "completed"
																? Color.line
																		.secondary
																: Color.status
																		.color
																		.error
														}
														style={{
															backgroundColor:
																row.state ===
																"active"
																	? "#EBF9FF"
																	: row.state ===
																	  "completed"
																	? Color
																			.background
																			.secondaryLow
																	: Color
																			.status
																			.color
																			.errorDefault,
														}}
													/>
												) : column.id ===
												  "volunteering" ? (
													<Row style={{ gap: 8 }}>
														{row.To &&
															row.To
																.volunteering &&
															row.To.volunteering.map(
																(
																	item: string
																) => (
																	<Label
																		text={
																			item ===
																			"offline"
																				? "Presencial"
																				: "Online"
																		}
																		color={
																			item ===
																			"offline"
																				? "#2D55B5"
																				: "#5963F6"
																		}
																		style={{
																			backgroundColor:
																				item ===
																				"offline"
																					? "#EBF9FF"
																					: "#EBECFF",
																		}}
																	/>
																)
															)}
														{row.data &&
															row.data
																.volunteering &&
															row.data.volunteering.map(
																(
																	item: string
																) => (
																	<Label
																		text={
																			item ===
																			"offline"
																				? "Presencial"
																				: "Online"
																		}
																		color={
																			item ===
																			"offline"
																				? "#2D55B5"
																				: "#5963F6"
																		}
																		style={{
																			backgroundColor:
																				item ===
																				"offline"
																					? "#EBF9FF"
																					: "#EBECFF",
																		}}
																	/>
																)
															)}
													</Row>
												) : column.id ===
												  "coorporate" ? (
													<Label
														text={
															row.Coorporate ||
															(row.To &&
																row.To
																	.Coorporate)
																? "Corp."
																: "App"
														}
														color={
															row.Coorporate ||
															(row.To &&
																row.To
																	.Coorporate)
																? "#2D55B5"
																: "#5963F6"
														}
														style={{
															backgroundColor:
																row.Coorporate ||
																(row.To &&
																	row.To
																		.Coorporate)
																	? "#EBF9FF"
																	: "#EBECFF",
														}}
													/>
												) : column.id === "product" ? (
													<Row>
														<Text type="p">
															{row.Product?.price}{" "}
															€/
															{row.Product
																?.charge ===
															"monthly"
																? "mes"
																: row.Product
																		?.charge ===
																  "annually"
																? "año"
																: ""}
														</Text>
													</Row>
												) : column.id ===
												  "residence" ? (
													<Label
														text={
															row.Residence
																? "Residencia"
																: "Domicilio"
														}
														color={
															row.Residence
																? "#2D55B5"
																: "#5963F6"
														}
														style={{
															backgroundColor:
																row.Residence
																	? "#EBF9FF"
																	: "#EBECFF",
														}}
													/>
												) : column.id ===
												  "challenge" ? (
													<Label
														text={
															row.type === "dream"
																? "Sueño"
																: row.type ===
																  "signature"
																? "Firmas"
																: "Cumpleaños"
														}
														color={
															row.type === "dream"
																? "#2D55B5"
																: row.type ===
																  "signature"
																? "#001D3D8F"
																: "#5963F6"
														}
														style={{
															backgroundColor:
																row.type ===
																"dream"
																	? "#EBF9FF"
																	: row.type ===
																	  "signature"
																	? "#001D3D0A"
																	: "#EBECFF",
														}}
													/>
												) : column.id === "name" ? (
													<Row>
														<Avatar
															name={row.name}
															icon={
																row.image
																	? row.image
																			.url
																	: row.logo
																	? row.logo
																			.url
																	: undefined
															}
															style={{
																height: 30,
																width: 30,
																marginRight: 8,
																fontSize: 15,
															}}
														/>
														<Text type="p2">
															{row.name}{" "}
															{row.surname}
														</Text>
													</Row>
												) : column.id === "user" ? (
													<Row>
														<Avatar
															name={
																row.User?.name
															}
															icon={
																row.User?.image
																	? row.User
																			.image
																			.url
																	: row.logo
																	? row.logo
																			.url
																	: undefined
															}
															style={{
																height: 30,
																width: 30,
																marginRight: 8,
																fontSize: 15,
															}}
														/>
														<Text type="p2">
															{row.User?.name}{" "}
															{row.User?.surname}
														</Text>
													</Row>
												) : column.id === "To" ||
												  column.id === "Admin" ? (
													<Row
														style={{
															position:
																"relative",
														}}
													>
														{row.To && (
															<>
																<Avatar
																	name={
																		row[
																			column
																				.id
																		]?.name
																	}
																	icon={
																		row[
																			column
																				.id
																		]?.image
																			? row[
																					column
																						.id
																			  ]
																					?.image
																					.url
																			: undefined
																	}
																	style={{
																		height: 30,
																		width: 30,
																		marginRight: 8,
																		fontSize: 15,
																	}}
																/>
																<Text type="p2">
																	{
																		row[
																			column
																				.id
																		]?.name
																	}{" "}
																	{
																		row[
																			column
																				.id
																		]
																			?.surname
																	}
																</Text>
															</>
														)}
													</Row>
												) : column.id ===
												  "To.isSubscriptor" ? (
													<Row
														style={{
															position:
																"relative",
														}}
													>
														{row.To
															.isSubscriptor && (
															<CrownView>
																<Euro
																	height={16}
																	width={16}
																/>
															</CrownView>
														)}
													</Row>
												) : column.id === "active" ? (
													<Label
														text={
															row.active
																? "Activa"
																: "Inactiva"
														}
														color={
															row.active
																? "#2D55B5"
																: Color.status
																		.color
																		.error
														}
														style={{
															backgroundColor:
																row.active
																	? "#EBF9FF"
																	: Color
																			.status
																			.color
																			.errorDefault,
														}}
													/>
												) : column.id ===
												  "createdAt" ? (
													<Text type="p2">
														{moment(
															row.createdAt
														).format("DD/MM/YYYY")}
													</Text>
												) : column.id ===
												  "startDate" ? (
													<Text type="p2">
														{moment(
															row.startDate.iso
														).format("DD/MM/YYYY")}
													</Text>
												) : column.id === "endDate" ? (
													<Text type="p2">
														{moment(
															row.endDate.iso
														).format("DD/MM/YYYY")}
													</Text>
												) : column.id === "price" ? (
													<Text
														type="p2"
														style={{
															color:
																row.price === 0
																	? Color
																			.status
																			.color
																			.warning
																	: Color.text
																			.full,
														}}
													>
														{row.price === 0
															? "Gratis"
															: new Intl.NumberFormat(
																	"es-ES",
																	{
																		style: "currency",
																		currency:
																			row.currency,
																		maximumFractionDigits: 0,
																	}
															  ).format(
																	row.price
															  )}
													</Text>
												) : column.id === "To.city" ? (
													<Text type="p2">
														{row.To.city}
													</Text>
												) : column.id === "amount" ||
												  column.id === "goal" ? (
													<Text type="p2">
														{row[column.id]} €
													</Text>
												) : column.id === "priority" ? (
													<row.priority.lucide
														height={
															row.isReminder
																? 20
																: 24
														}
														width={
															row.isReminder
																? 20
																: 24
														}
														color={
															row.priority.color
														}
														fill={
															row.isReminder
																? row.priority
																		.color
																: "transparent"
														}
													/>
												) : column.id ===
												  "payment-type" ? (
													<Text type="p">
														{row.type ===
														"recurrent"
															? "Recurrente"
															: row.type ===
															  "first"
															? "Primer pago"
															: row.type === "one"
															? "Pago puntual"
															: ""}
													</Text>
												) : column.id === "type" ? (
													<TypeView>
														{Object.keys(row.type)
															.sort((a, b) =>
																a.localeCompare(
																	b
																)
															)
															.map(
																(
																	key,
																	index
																) => {
																	const isActive: any =
																		row
																			.type[
																			key
																		];
																	return (
																		<Label
																			key={
																				"type" +
																				index
																			}
																			type="chip"
																			style={{
																				marginRight: 4,
																			}}
																			size={
																				"big"
																			}
																			text={
																				key ===
																				"call"
																					? "Llamadas"
																					: key ===
																					  "letters"
																					? "Cartas"
																					: key ===
																					  "offline"
																					? "Visitas"
																					: key
																			}
																			disabled={
																				!isActive
																			}
																		/>
																	);
																}
															)}
													</TypeView>
												) : column.id.startsWith(
														"data"
												  ) ? (
													<Text
														type="p2"
														style={{
															color:
																column.midValue &&
																column.minValue &&
																column.compare &&
																row.data
																	? row.data[
																			column.id.substring(
																				column.id.indexOf(
																					"."
																				) +
																					1
																			)
																	  ] /
																			row
																				.data[
																				column
																					.compare
																			] >=
																	  column.minValue
																		? Color
																				.status
																				.color
																				.error
																		: row
																				.data[
																				column.id.substring(
																					column.id.indexOf(
																						"."
																					) +
																						1
																				)
																		  ] /
																				row
																					.data[
																					column
																						.compare
																				] >=
																		  column.midValue
																		? Color
																				.status
																				.color
																				.warning
																		: Color
																				.status
																				.color
																				.success
																	: Color.text
																			.full,
														}}
													>
														{row.data &&
														row.data[
															column.id.substring(
																column.id.indexOf(
																	"."
																) + 1
															)
														]
															? row.data[
																	column.id.substring(
																		column.id.indexOf(
																			"."
																		) + 1
																	)
															  ]
															: "0"}
													</Text>
												) : (
													<Text type="p2">
														{row[column.id]}
													</Text>
												)}
											</td>
										))}
									</TableRow>
								))}
							</tbody>
						</TableStyled>
					)
				) : selectedOption === "columns" ? (
					<Columns
						lists={
							props.filters.filter(
								(item) => item.id === "state"
							)[0].options
						}
						rows={props.rows}
						onClick={onRowClick}
					/>
				) : (
					<MapView
						style={{ right: 0, left: 0, top: 72, bottom: 0 }}
						userArray={props.rows}
						city={address}
						onLocationChange={onLocationChange}
						onRowClick={(row) => onRowClick(row, "_blank")}
					/>
				)}
			</TableContainer>
			{(selectedOption === "list" ||
				selectedOption === "data" ||
				selectedOption === "hot" ||
				selectedOption === "map") && (
				<PageContainer>
					<PageViewLeft>
						<Button
							design="image"
							icon={<RefreshCcw height={20} width={20} />}
							onClick={() =>
								props.onFiltersChange &&
								props.onFiltersChange(selectedFilters)
							}
						/>
						<Text type="p2">
							<span style={{ fontWeight: 600 }}>
								{props.count}
							</span>{" "}
							{props.title.toLowerCase()}
						</Text>
						{props.secondaryCount && (
							<Text type="p2" style={{ marginLeft: 18 }}>
								<span style={{ fontWeight: 600 }}>
									{props.secondaryCount}
								</span>{" "}
								{props.secondaryTitle}
							</Text>
						)}
					</PageViewLeft>
					<PageViewCenter>
						<Pagination
							start={props.page}
							length={props.count}
							rowsPerPage={props.rows.length}
							onChange={onPageChange}
						/>
					</PageViewCenter>
					<PageViewRight />
				</PageContainer>
			)}
		</Container>
	);
};
export default Table;
export interface Props {
	title: string;
	loading?: boolean;
	filters: Array<{
		style?: CSSProperties;
		onChange?: (r: any) => void;
		id: string;
		placeholder: string;
		disabled?: boolean;
		type: "single" | "multiple" | "ratio" | "date";
		options?: Array<{
			id: string;
			label: string;
			sublabel?: string;
		}>;
		selectedOptions?: Array<string>;
		hideSearchBar?: boolean;
		position?: "bottom-right" | "bottom-left";
	}>;
	columns: Array<{
		id: string;
		title: string;
		minValue?: number;
		midValue?: number;
		compare?: string;
	}>;
	switchOptions?: Array<{
		id: string;
		icon: ReactElement;
	}>;
	rows: Array<any>;
	count: number;
	secondaryCount?: number;
	secondaryTitle?: string;
	page?: number;
	disabled?: boolean;
	ButtonView?: () => JSX.Element;
	onRowClick?: (row: any, target?: string) => void;
	onFiltersChange?: (result: Object) => void;
}

import React, { useEffect, useRef, useState } from "react";
import {
	Residence,
	ResidenceScheme,
	ErrorScheme,
} from "client-v2";
import styled from "styled-components";
import moment from "moment";
import { useSelector } from "react-redux";
import { State } from "../../../redux";
import { MenuRef } from "@adoptaunabuelo/react-components/dist/cjs/components/Menu/Menu";

import Card from "../../../components/Containers/Card";
import ResidenceInfoModal from "../../../components/Modals/ResidenceInfoModal";
import {
	Text,
	Color,
	Button,
	Modal,
	Menu,
	Label,
	Avatar,
	Checkbox,
	ColorV2,
} from "@adoptaunabuelo/react-components";
import { Menu as MenuIcon, Edit2, Plus } from "lucide-react";

const TitleHeader = styled.div`
	display: flex;
	margin-bottom: 13px;
	align-items: center;
`;
const TopView = styled.div`
	display: flex;
	flex-direction: row;
	margin: 12px 0px;
	flex-flow: row wrap;
	align-items: center;
`;
const BottomView = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	padding-top: 8px;
	border-top: 1px solid ${Color.line.soft};
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

const SectionMain = (props: Props) => {
	const role = useSelector((state: State) => state.role);

	const [loading, setLoading] = useState(false);
	const [showEditModal, setShowEditModal] = useState(false);
	const [residenceOptions, setResidenceOptions] = useState<Array<any>>([]);
	const [typeOptions, setTypeOptions] = useState<Array<any>>([]);
	const [showModal, setShowModal] = useState(false);
	const [selectedState, setSelectedState] = useState(props.residence.state);
	const menuRef = useRef<MenuRef>(null);


	useEffect(() => {
		generateResidenceOptions(props.residence);
		generateTypeData(props.residence);
	}, [props.residence]);

	const generateTypeData = (residence: ResidenceScheme) => {
		const resultOptions = [
			{
				id: "offline",
				label: "Visitas",
				defaultSelection: residence.type.offline,
			},
			{
				id: "call",
				label: "Llamadas",
				defaultSelection: residence.type.call,
			}
		];
		setTypeOptions(resultOptions);
	};

	const generateResidenceOptions = (residence: ResidenceScheme) => {
		let tmpArray = [
			{
				id: "edit-profile",
				label: "Editar",
				icon: <Edit2 height={20} width={20} />,
			},
		];
		setResidenceOptions(tmpArray);
	};

	const userOptionClick = (item: any) => {
		if (item.id === "edit-profile") {
			setShowEditModal(true);
		} else if (item.id === "active" || item.id === "inactive") {
			setShowModal(true);
		}
	};

	const onTypeChange = (optionsResult: Array<any>) => {
		const temp = typeOptions.map((item) => {
			if (
				optionsResult.findIndex(
					(tempIndex) => tempIndex.id === item.id
				) > -1
			) {
				return { ...item, defaultSelection: true };
			} else {
				return { ...item, defaultSelection: false };
			}
		});
		setTypeOptions(temp);
	};

	const editType = async () => {
		setLoading(true);
		let result: any = {};
		typeOptions.map((item) => {
			if (item.defaultSelection)
				result = {
					...result,
					[item.id]: true,
				};
			else {
				result = {
					...result,
					[item.id]: false,
				};
			}
		});

		Residence.set(props.residence.objectId, {
			type: result,
		})
			.then((residence) => {
				props.onResidenceChanged(residence.data);
				menuRef.current?.close();
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setLoading(false);
			});
	};

	const onModalClick = async () => {
		setLoading(true);
		Residence.set(props.residence.objectId, {
			state: selectedState,
		})
			.then((residence) => {
				setLoading(true);
				props.onResidenceChanged(residence.data);
				setShowModal(false);
			})
			.catch((error: ErrorScheme) => {
				setLoading(false);
			});
	};

	return (
		<>
			<ResidenceInfoModal
				isVisible={showEditModal}
				residence={props.residence}
				residenceGroup={props.residence.ResidenceGroup}
				onClose={() => setShowEditModal(false)}
				onSave={(e: ResidenceScheme) => {
					props.onResidenceChanged(e);
					setShowEditModal(false);
				}}
			/>
			<Modal
				isVisible={showModal}
				title="¿Seguro que quieres continuar?"
				subtitle={
					"Se marcará la residencia como " +
					(selectedState === "active"
						? "activa"
						: selectedState === "completed"
						? "completa"
						: "inactiva")
				}
				buttonProps={{
					children: "Guardar",
					loading: loading,
					onClick: onModalClick,
				}}
				onClose={() => setShowModal(false)}
			/>
			<Card style={{ marginBottom: 16 }}>
				<TitleHeader>
					<Avatar
						name={props.residence.name}
						icon={
							props.residence.image && props.residence.image.url
						}
						clickable={props.residence.image ? true : false}
					/>
					<Row
						style={{
							flex: 1,
							justifyContent: "flex-end",
							marginRight: 12,
						}}
					>
						<Menu
							id={"state-menu"}
							position={"bottom-right"}
							menuStyle={{ top: 32, width: 200 }}
							Icon={
								<Label
									text={
										props.residence.state === "active"
											? "Activa"
											: props.residence.state ===
											  "completed"
											? "Completa"
											: "Inactiva"
									}
									color={
										props.residence.state === "active"
											? "#2D55B5"
											: props.residence.state ===
											  "completed"
											? Color.line.secondary
											: Color.status.color.error
									}
									style={{
										backgroundColor:
											props.residence.state === "active"
												? "#EBF9FF"
												: props.residence.state ===
												  "completed"
												? Color.background.secondaryLow
												: Color.status.color
														.errorDefault,
									}}
								/>
							}
							options={
								role.residence.remove
									? [
											{
												id: "active",
												label: "Activa",
											},
											{
												id: "completed",
												label: "Completa",
											},
											{
												id: "inactive",
												label: "Inactiva",
											},
											{
												id: "deleted",
												label: "Eliminada",
											},
									  ]
									: [
											{
												id: "active",
												label: "Activa",
											},
											{
												id: "completed",
												label: "Completa",
											},
											{
												id: "inactive",
												label: "Inactiva",
											},
									  ]
							}
							onClick={(option) => {
								setSelectedState(option.id as any);
								setShowModal(true);
							}}
						/>
					</Row>
					<Menu
						id="user-options"
						position={"bottom-right"}
						menuStyle={{ width: "unset" }}
						icon={<MenuIcon />}
						options={residenceOptions}
						onClick={(option) => userOptionClick(option)}
					/>
				</TitleHeader>
				<Text type="p" weight={"semibold"}>
					{props.residence.name}
				</Text>
				{props.residence.ResidenceGroup && (
					<>
						<Text
							type="p2"
							style={{
								marginBottom: 8,
								color: ColorV2.text.neutralMedium,
							}}
						>
							{props.residence.ResidenceGroup.name}
						</Text>
					</>
				)}
				<Text type="p" style={{ marginBottom: 8 }}>
					{props.residence.address}
				</Text>
				<Text type="p">{props.residence.phone}</Text>
				<TopView>
					{typeOptions.map((item) => {
						if (item.defaultSelection)
							return (
								<Label
									type="chip"
									key={item.id}
									style={{ margin: 2 }}
									size={"selector"}
									text={item.label}
								/>
							);
					})}
					<Menu
						id={"programOptions"}
						position={"bottom-right"}
						menuStyle={{ height: "unset" }}
						ref={menuRef}
						icon={<Plus color={Color.text.high} />}
					>
						<Checkbox
							type="multiple"
							options={typeOptions}
							selectedOptions={typeOptions.filter(
								(item) => item.defaultSelection
							)}
							onChange={onTypeChange}
						/>
						<BottomView>
							<Button
								style={{ height: 38, padding: 12 }}
								onClick={editType}
								loading={loading}
							>
								Guardar
							</Button>
						</BottomView>
					</Menu>
				</TopView>
				<Row style={{ alignItems: "center", marginTop: 12 }}>
					<Text
						type="p"
						style={{
							display: "flex",
							flex: 1,
							fontSize: 12,
							color: Color.text.high,
						}}
						weight={"semibold"}
					>
						Registrada el{" "}
						{moment(props.residence.createdAt).format("DD/MM/YYYY")}
					</Text>
				</Row>
			</Card>
		</>
	);
};

export default SectionMain;
export interface Props {
	residence: ResidenceScheme;
	onResidenceChanged: (residence: ResidenceScheme) => void;
}

import React, { ComponentPropsWithoutRef, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { User, UserScheme, Auth, ErrorScheme } from 'client-v2';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actionsCreators, State } from '../../redux';
import { bindActionCreators } from 'redux';

import { Color, Menu, Text, Avatar } from '@adoptaunabuelo/react-components';
import { User as UserIcon, LogOut, CheckSquare, RockingChair, School, Briefcase, CalendarDays, Heart, PenSquare, BadgeEuro, HeartHandshake, Megaphone } from 'lucide-react';
import PhoneModal from '../Phone/PhoneModal';
import IncidenceModal from '../Modals/IncidenceModal';
import { AdminRole, SalesRole, SupportRole } from '../../roles';
import FavView from './FavView';

const Container = styled.div `
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    background: ${Color.background.deepBlue};
    width: 62px;
    box-shadow: 1px 0px 0px #F2F2F2;
    padding: 16px 0px 40px;
`;
const Logo = styled.img`
    height: 48px;
    width: 48px;
    margin-bottom: 32px;
`;
const ItemTitle = styled.div<{isSelected:boolean}>`
    display: none;
    position: absolute;
    left: 36px;
    background-color: ${props => props.isSelected ? 'white' : Color.text.primaryBlack};
    padding: 2px 8px;
    border-radius: 6px;
    z-index: 1000;
`
const ItemNav = styled.div<{isSelected:boolean}>`
    position: relative;
    height: 42px;
    width: 42px;
    min-height: 42px;
    min-width: 42px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: ${props=> props.isSelected ? 'white' : "transparent"};
    cursor: pointer;
    border-radius: 12px;
    margin-bottom: 6px;
    &:hover{
       background: ${props => props.isSelected ? 'white' : Color.text.primaryBlack};
    }
    &:hover ${ItemTitle}{
        display: block;
    }
`;
const ItemsView = styled.div`
    display: flex;
    align-items: center;
    flex: 1;
    flex-direction: column;
    width: 100%;
`
const AdminView = styled.div`
    position: relative;
`
const Cell = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 16px;
    cursor: pointer;
    &:hover{
        background-color: ${Color.background.soft};
    }
`
const Dot = styled.div`
    height: 10px;
    width: 10px;
    border-radius: 100px;
    z-index: 10;
`
const SideBar = (props: Props) =>{

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const menu = useRef<any>(null);

	const { setCurrentUser, setError, setRole } = bindActionCreators(actionsCreators, dispatch);
    const currentUser: UserScheme = useSelector((state:State)=>state.currentUser);
    const role = useSelector((state:State)=>state.role);

    const [ favMenu, setFavMenu ] = useState<any>({});
    const [ currentState, setCurrentState ] = useState<any>(undefined);
    const [ currentSection, setCurrentSection ] = useState<any>(undefined);
    const [ showIncidenceModal, setShowIncidenceModal ] = useState(false);
    const [ sections, setSections ] = useState<Array<any>>([
        {
            id: 'tareas',
            title: 'Tareas',
            icon: CheckSquare
        },
        {
            id: 'ventas',
            title: 'Ventas',
            icon: BadgeEuro
        },
        {
            id: 'voluntarios',
            title: 'Voluntarios',
            icon: UserIcon
        },
        {
            id: 'abuelos',
            title: 'Abuelos',
            icon: RockingChair
        },
        {
            id: 'embajadores',
            title: 'Embajadores',
            icon: HeartHandshake
        },
        {
            id: 'residencias',
            title: 'Residencias',
            icon: School
        },
        {
            id: 'corporativo',
            title: 'Corporativo',
            icon: Briefcase
        },
        {
            id: 'evento',
            title: 'Eventos',
            icon: CalendarDays
        },
        {
            id: 'reto',
            title: 'Sueños',
            icon: Heart
        },
        {
            id: 'push',
            title: 'Enviar push',
            icon: Megaphone
        }
    ]);

    const menuOptions = [
        {
            id: 'available',
            title: 'Disponible',
            color: Color.status.color.success
        },
        {
            id: 'absent',
            title: 'Ocupado',
            color: Color.status.color.warning
        },
        {
            id: 'logout',
            title: 'Cerrar sesión',
            icon: LogOut
        }
    ]

    useEffect(()=>{
        onPhoneStateChange('available');
        initPushNotifications();
    },[])

    useEffect(() =>{
        setMenu();
        window.addEventListener('storage-fav-menu', () => {
            setMenu();
        });
    }, []);

    useEffect(() =>{
        if(currentUser){            
            //Find role
            if(currentUser.data?.department === 'admin'){
                setRole(AdminRole);
            }
            else if(currentUser.data?.department === 'sales'){
                setRole(SalesRole);
            }
            else{
                setRole(SupportRole);
            }
        }
    },[currentUser]);

    useEffect(() =>{
        const data = currentUser ? currentUser.data : undefined;
        const temp = sections.map(item => {
            if(role.pages[item.id])
                return item;
        });
        setSections(temp.filter(item => item));
    },[role]);

    useEffect(() =>{
        if(window.location.href){
            const result = sections.find(item => window.location.href.includes(item.id));
            setCurrentSection(result);
        }
    },[]);

    const setMenu = () =>{
        const temp = localStorage.getItem('fav-menu');
        const temp2 = temp ? JSON.parse(temp) : {};
        if(Object.values(temp2).filter(i => i === true).length > 0){
            setFavMenu(temp2);
        }
        else{
            //Init the fav menu
            if(currentUser?.data?.department === 'admin'){
                setFavMenu(AdminRole.pagesFav);
                localStorage.setItem('fav-menu', JSON.stringify(AdminRole.pagesFav));
                dispatchEvent(new Event("storage-fav-menu"));
            }
            else if(currentUser?.data?.department === 'sales'){
                setFavMenu(SalesRole.pagesFav);
                localStorage.setItem('fav-menu', JSON.stringify(SalesRole.pagesFav));
                dispatchEvent(new Event("storage-fav-menu"));
            }
            else{
                setFavMenu(SupportRole.pagesFav);
                localStorage.setItem('fav-menu', JSON.stringify(SupportRole.pagesFav));
                dispatchEvent(new Event("storage-fav-menu"));
            }
        }
    }

    const initPushNotifications = () =>{
        if (!("Notification" in window)) 
            console.log("This browser does not support desktop notification"); 
        else
            Notification.requestPermission();
    }

    const onPhoneStateChange = (state: string) =>{
        const item = menuOptions.filter(item => item.id === state)[0];
        setCurrentState(item);
        onCellClick(item);
    }

    const onSectionClick = (item: any) =>{
        setCurrentSection(item);
        navigate('/'+item.id);
    }

    const logout = () =>{
        Auth.logout();
        setCurrentUser(null);

        //Clean the fav menu
        localStorage.setItem('fav-menu', JSON.stringify({}));
        dispatchEvent(new Event("storage-fav-menu"));

        navigate('/auth', {
            replace: true
        });
    }

    const onCellClick = async (item: any) =>{
        if(item.id === 'logout'){
            logout();
        }
        else{
            if(currentUser){
                //Change user state
                User.set(currentUser.objectId, {
                    state: item.id
                }).then((result) =>{
                    if(menu && menu.current)
                        menu.current.close();
                    setCurrentUser(result.data);
                    setCurrentState(item)
                }).catch((error: ErrorScheme) =>{
                    setError(true, error.message);
                });
            }
        }
    }

    const renderCell = (item: any) =>(
        <Cell 
            key={item.id} 
            style={{borderTop: item.id === 'logout' ? '1px solid '+Color.line.soft : 'none'}}
            onClick={() => onCellClick(item)}
        >
            {item.color ?
                <Dot
                    style={{backgroundColor: item.color, marginRight: 8}}
                />
            : item.icon ?
                <item.icon style={{marginRight: 4}} color={Color.text.full} height={18} width={18}/>
            : null}
            <Text type='p2'>
                {item.title}
            </Text>
        </Cell>
    )

    return(currentUser ?
        <Container style={props.style}>
            <Logo src={require('../../assets/img/brand/side_bar.png')}/>
            {currentUser.data?.trelloId &&
                <ItemNav 
                    key={'incidence-modal'}
                    style={{backgroundColor: Color.background.primary, marginBottom: 24}}
                    isSelected
                    onClick={() => setShowIncidenceModal(true)}
                >
                    <PenSquare width={20} height={20} color={'white'}/>
                    <ItemTitle
                        isSelected={true}
                        style={{backgroundColor: Color.background.primary}}
                    >
                        <Text type='p' style={{color: 'white'}}>
                            Incidencia
                        </Text>
                    </ItemTitle>
                </ItemNav>
            }
            <ItemsView>
                {sections.map(item =>{
                    const isFav = favMenu[item.id] ? true : false;
                    if(isFav){
                        const isSelected = currentSection ? item.id === currentSection.id : false;
                        return(
                            <ItemNav 
                                key={'section'+item.id}
                                isSelected={isSelected}
                                onClick={() => onSectionClick(item)}
                            >
                                <item.icon width={20} height={20} color={isSelected ? Color.text.deepBlue : Color.text.whiteHigh}/>
                                <ItemTitle
                                    isSelected={isSelected}
                                >
                                    <Text type='p' style={{color: isSelected ? Color.text.deepBlue : Color.text.whiteHigh}}>
                                        {item.title}
                                    </Text>
                                </ItemTitle>
                            </ItemNav>
                        )
                    }
                    else return null;
                })}
                <FavView
                    options={sections}
                    onClick={onSectionClick}
                />
            </ItemsView>
            <AdminView>
                <Dot
                    style={{
                        position: 'absolute', 
                        bottom: 6, 
                        right: 6,
                        height: 16,
                        width: 16,
                        border: '2px solid '+Color.line.deepBlue,
                        backgroundColor: currentState ? currentState.color : 'white'
                    }}
                />
                <Menu
                    ref={menu}
                    id={'User-Menu-Sidebar'}
                    icon={<Avatar 
                        style={{borderRadius: 12, height: 48, width: 48, cursor: 'pointer'}}
                        name={currentUser.name} 
                        icon={currentUser.image?.url}/>
                    }
                    position={'top-right'}
                    menuStyle={{width: 160, height: 'unset', bottom: 32, left: 64, padding: 0 }}
                >
                    {menuOptions.map(item =>(
                        renderCell(item)
                    ))}
                </Menu>
            </AdminView>
            <PhoneModal
                onStateChange={onPhoneStateChange}
            />
            <IncidenceModal
                isVisible={showIncidenceModal}
                currentUser={currentUser}
                onClose={() => setShowIncidenceModal(false)}
            />
        </Container>
    : null)
}
export default SideBar;
export interface Props extends ComponentPropsWithoutRef<"div">{

}
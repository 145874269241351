import React, {
	Ref,
	forwardRef,
	useEffect,
	useImperativeHandle,
	useState,
} from "react";
import styled from "styled-components";
import CSVReader from "react-csv-reader";
import { json2csv } from "json-2-csv";
import moment from "moment";

import {
	Text,
	ColorV2,
	Dropdown,
	Button,
} from "@adoptaunabuelo/react-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;
`;
const Group = styled.div``;
const Section = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid ${ColorV2.border.neutralSoft};
	padding: 8px 0px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 12px;
`;
const TableStyled = styled.table`
	width: 100%;
	white-space: nowrap;
`;
const TableRow = styled.tr`
	height: 32px;
`;
const WarningView = styled.div`
	display: flex;
	flex-direction: column;
	padding: 12px;
	background-color: ${ColorV2.surface.background};
	border-radius: 12px;
	margin-bottom: 8px;
`;

const selectedOptionsInmark: any = [
	{ id: "user_id", title: "user_id" },
	{ id: "user_nif", title: "user_nif" },
	{ id: "phone", title: "phone" },
	{ id: "e_mail", title: "e_mail" },
	{ id: "postal_code", title: "postal_code" },
	{ id: "user_birthday", title: "user_birthday" },
	{ id: "IBAN", title: "IBAN" },
	{ id: "cuota", title: "cuota" },
	{ id: "periodicidad", title: "periodicidad" },
	{ id: "fecha_primer_pago", title: "fecha_primer_pago" },
];

const processItemValue = (item: any, value: string, type: string) => {
	if (type === "date") {
		return moment(item[value], "DD/MM/YYYY").toDate();
	} else if (type === "string") {
		return typeof item[value] === "string"
			? item[value]
			: String(item[value]);
	} else if (type === "phone") {
		return `+34${item[value]}`
	} else {
		return item[value];
	}
};

const LoadCSV = forwardRef((props: LoadCSVProps, ref: Ref<LoadCSVRef>) => {
	const [step, setStep] = useState(0);
	const [csvKeys, setCsvKeys] = useState<
		Array<{ id: string; title: string }>
	>([]);
	const [csvData, setCsvData] = useState<Array<Object>>([]);
	const [columns, setColumns] = useState<any>({});
	const [successData, setSuccessData] = useState<Array<any> | undefined>(
		undefined
	);
	const [errorData, setErrorData] = useState<Array<any> | undefined>(
		undefined
	);
	const [showErrors, setShowErrors] = useState(false);

	useEffect(() => {
		setStep(0);
		setCsvData([]);
		setCsvKeys([]);
		setColumns({
			user: {
				name: "contact_name",
				surname: "user_surname",
				objectId: "user_id",
				idCard: "user_nif",
				phone: "phone",
				email: "e_mail",
				address: "address",
				city: "city",
				zipCode: "postal_code",
				birthday: "user_birthday",
			},
			subscription: {
				IBAN: "iban",
				price: "cuota",
				charge: "periodicidad",
				endDate: "fecha_primer_pago",
			},
		});
		setShowErrors(false);
		setSuccessData(undefined);
		setErrorData(undefined);
	}, []);

	useImperativeHandle(ref, () => ({
		getData() {
			if (step === 1) {
				//Check if all needed columns are set
				let neededColumnsError: string | undefined = undefined;
				for (var i = 0; i < props.keys.length; i++) {
					if (neededColumnsError) {
						break;
					} else {
						const key = props.keys[i];
						for (var j = 0; j < key.data.length; j++) {
							const data = key.data[j];
							if (data.needed) {
								if (columns[key.id]) {
									if (!columns[key.id][data.id]) {
										neededColumnsError =
											"Faltan por seleccionar campos obligatorios";
										break;
									}
								} else {
									neededColumnsError =
										"Faltan por seleccionar campos obligatorios";
									break;
								}
							}
						}
					}
				}

				if (neededColumnsError) {
					return {
						error: neededColumnsError,
					};
				}
				//Create the object
				else {
					const result = csvData.map((item: any) => {
						let temp: any = {};
						const columnEntries = Object.entries(columns);
						columnEntries.map(([group, values]: any) => {
							Object.entries(values).map(([key, value]: any) => {
								//check if is a needed data
								let neededData: boolean = false;
								let type = "string";
								const keyGroup = props.keys.filter(
									(i) => i.id === group
								);
								if (keyGroup.length > 0) {
									const keyData = keyGroup[0].data.filter(
										(i) => i.id === key
									);
									if (keyData.length > 0) {
										neededData = keyData[0].needed
											? keyData[0].needed
											: false;
										type = keyData[0].type
											? keyData[0].type
											: "string";
									}
								}
								if (neededData && !item[value]) {
									temp = {
										...temp,
										[group]: {
											...temp[group],
											[key]: item[value],
										},
										error: {
											...temp.error,
											[value]: "Falta dato obligatorio",
										},
									};
								} else {
									temp = {
										...temp,
										[group]: {
											...temp[group],
											[key]: processItemValue(
												item,
												value,
												type
											),
										},
									};
								}
							});
						});
						return temp;
					});

					//Divide the array in errors and success
					const success = result.filter((i) => !i.error);
					const errors = result
						.filter((i) => i.error)
						.map((group) => {
							let temp = {};
							Object.entries(group).map(([keys, values]: any) => {
								if (keys === "error") {
									Object.entries(values).map(
										([key, value]) => {
											temp = {
												...temp,
												[key]: value,
											};
										}
									);
								}
							});
							return {
								userId: group.user.objectId,
								...temp,
							};
						});
					setSuccessData(success);
					setErrorData(errors);

					setStep(step + 1);
				}
			} else if (step === 2) {
				return {
					result: {
						success: successData ? successData : [],
						errors: errorData ? errorData : [],
					},
				};
			}
		},
		downloadErrors(data: Array<any>) {
			if (data.length > 0) {
				const temp: Array<any> = [];
				const columnId = columns.user.objectId;
				data.map((item) => {
					const userId = item.user.objectId;
					csvData.map((j: any) => {
						if (j[columnId] === userId) {
							temp.push(j);
						}
					});
				});

				//Generate CSV
				const csv = json2csv(temp, {
					emptyFieldValue: "",
				});

				//Download file
				const url = window.URL.createObjectURL(new Blob([csv]));
				const link = document.createElement("a");
				link.href = url;
				const fileName = `load_errors.csv`;
				link.setAttribute("download", fileName);
				document.body.appendChild(link);
				link.click();
				link.remove();
			}
		},
		goStepBack() {
			const prevStep = step - 1;
			setStep(prevStep);
			return prevStep;
		},
	}));

	const onFileLoaded = (data: Array<any>) => {
		setCsvKeys(
			Object.keys(data[0]).map((item) => ({ id: item, title: item }))
		);
		setCsvData(data);
		setStep(step + 1);
	};

	const onColumnChange = (newSelection: any, group: string, id: string) => {
		setColumns({
			...columns,
			[group]: {
				...columns[group],
				[id]: newSelection.id,
			},
		});
	};

	const defaultValues = (
		indexgroup: number,
		indexkey: number,
		groupid: string,
		keyid: string
	) => {
		if (!groupid || !keyid) {
			return [
				{
					id: selectedOptionsInmark[indexgroup + indexkey * 6].id,
					title: selectedOptionsInmark[indexgroup + indexkey * 6]
						.title,
				},
			];
		} else {
			return [
				{
					id: columns[groupid][keyid],
					title: columns[groupid][keyid],
				},
			];
		}
	};

	return props.hidden ? null : step === 0 ? (
		<Container style={{ paddingBottom: 24 }}>
			<CSVReader
				cssClass="react-csv-input"
				onFileLoaded={onFileLoaded}
				parserOptions={{
					header: true,
					dynamicTyping: true,
					skipEmptyLines: true,
					transformHeader: (header: any) =>
						header.toLowerCase().replace(/\W/g, "_"),
				}}
			/>
		</Container>
	) : step === 1 ? (
		<Container>
			{csvData.length > 0 &&
				props.keys.map((group, indexkey) => (
					<Group key={"id_group_" + group.id}>
						<Text type="p" weight="semibold">
							{group.title}
						</Text>
						{group.data.map((key, indexgroup) => (
							<Section key={"id_section_" + key.id}>
								<Text type="p2">
									{key.title} {key.needed && "*"}
								</Text>
								<Dropdown
									id={key.id + "_selector"}
									style={{ flex: "none" }}
									menuStyle={{
										right: 0,
										left: "unset",
										maxWidth: 200,
									}}
									placeholder={"-"}
									type={"single"}
									options={csvKeys}
									optionStyle={{ right: 0 }}
									selectedOptions={defaultValues(
										indexgroup,
										indexkey,
										group.id,
										key.id
									)}
									onChange={(selection) =>
										onColumnChange(
											selection[0],
											group.id,
											key.id
										)
									}
								/>
							</Section>
						))}
					</Group>
				))}
		</Container>
	) : (
		<Container style={{ paddingBottom: 24, gap: 8 }}>
			{errorData && errorData.length > 0 && (
				<WarningView>
					<Text type="p" weight="semibold">
						⚠️ Antes de continuar
					</Text>
					<Text type="p2">
						Si existen errores en el documento, no se iniciará el
						proceso de carga. Comprueba las filas que contienen
						errores antes de continuar, soluciónalos y vuelve a
						iniciar el proceso.
					</Text>
				</WarningView>
			)}
			<Row>
				<Text type="p">
					Filas correctas:{" "}
					<span
						style={{ color: ColorV2.text.green, fontWeight: 600 }}
					>
						{successData?.length}
					</span>
				</Text>
			</Row>
			<Row>
				<Text type="p">
					Filas con errores:{" "}
					<span style={{ color: ColorV2.text.red, fontWeight: 600 }}>
						{errorData?.length}
					</span>
				</Text>
				{errorData && errorData.length > 0 && (
					<Button
						design={"call-to-action"}
						size="small"
						onClick={() => setShowErrors(!showErrors)}
					>
						{showErrors ? "Ocultar errores" : "Ver errores"}
					</Button>
				)}
			</Row>
			{showErrors && errorData && (
				<TableStyled>
					<tr>
						{Object.keys(errorData[0]).map((key) => (
							<th
								style={{
									textAlign: "left",
									borderBottom:
										"2px solid" +
										ColorV2.border.neutralMedium,
								}}
							>
								<Text type="p2" weight="semibold">
									{key}
								</Text>
							</th>
						))}
					</tr>
					<tbody>
						{errorData.map((item) => (
							<TableRow>
								{Object.keys(errorData[0]).map((key) => (
									<td
										style={{
											borderBottom:
												"1px solid " +
												ColorV2.border.neutralSoft,
										}}
									>
										<Text type="c1">{item[key]}</Text>
									</td>
								))}
							</TableRow>
						))}
					</tbody>
				</TableStyled>
			)}
		</Container>
	);
});
export default LoadCSV;
export interface LoadCSVProps {
	keys: Array<{
		id: string;
		title: string;
		data: Array<{
			id: string;
			title: string;
			needed?: boolean;
			type?: string;
		}>;
	}>;
	adminId?: string;
	hidden?: boolean;
}
export interface LoadCSVRef {
	getData: () => {
		result?: {
			success: Array<any>;
			errors: Array<any>;
		};
		error?: string;
	} | void;
	downloadErrors: (data: Array<any>) => void;
	goStepBack: () => number;
}

import { combineReducers } from 'redux'
import { 
    callReducer, 
    currentUserReducer, 
    roleReducer,
    autodialReducer
} from './call'
import { profileReducer } from './profile';
import { modalReducer } from './modal';
import { 
    volunteerTableFilterReducer, 
    grandpaTableFilterReducer,
    taskTableFilterReducer, 
    coorporateTableFilterReducer, 
    residenceTableFilterReducer,
    challengeTableFilterReducer,
    eventTableFilterReducer,
    ambassadorTableFilterReducer,
    salesTableFilterReducer
} from './filter';

const reducers = combineReducers({
    call: callReducer,
    profile: profileReducer,
    currentUser: currentUserReducer,
    modal: modalReducer,
    volunteerTableFilters: volunteerTableFilterReducer,
    grandpaTableFilters: grandpaTableFilterReducer,
    taskTableFilters: taskTableFilterReducer,
    coorporateTableFilters: coorporateTableFilterReducer,
    residenceTableFilters: residenceTableFilterReducer,
    challengeTableFilters: challengeTableFilterReducer,
    eventTableFilters: eventTableFilterReducer,
    role: roleReducer,
    autodial: autodialReducer,
    ambassadorTableFilters: ambassadorTableFilterReducer,
    salesTableFilters: salesTableFilterReducer,
});
export default reducers;

export type State = ReturnType<typeof reducers>
import React, { useState } from "react";
import styled from 'styled-components';
import moment from 'moment';
import { EventScheme, ProductScheme } from 'client-v2';

import Card from '../../../components/Containers/Card'
import { Color, Text, Button } from '@adoptaunabuelo/react-components';
import { Calendar, Map, Building, Clock, Pen } from 'lucide-react';
import DetailModal from "./DetailModal";

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 13px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`

const SectionMain = (props: Props) =>{

    const [ showModal, setShowModal ] = useState(false);

	return (
        <>
        <DetailModal
            isVisible={showModal}
            event={props.event}
            product={props.product}
            onClose={() => setShowModal(false)}
            onSave={(data) => {
                setShowModal(false);
                props.setEvent(data.event);
                props.setProduct(data.product);
            }}
        />
        <Card style={{marginBottom:16}}>
            <TitleHeader>
				<Text type='p' weight={"semibold"}>
					Detalles
				</Text>
				<Button 
                    style={{position: 'absolute', right: 16, top: 12}}
					design={'image'} 
					icon={<Pen height={20} width={20}/>}
                    onClick={() => setShowModal(true)}
				/>
			</TitleHeader>
            <Row
                style={{alignItems: 'center', cursor: 'pointer'}}
            >
                <Clock height={24} width={24} color={Color.text.high}/>
                <Text type='p' style={{marginLeft: 8}}>
                    {moment(props.product.startDate?.iso).format('HH:mm')} - {moment(props.product.endDate?.iso).format('HH:mm')}
                </Text>
            </Row>
            <Row
                style={{alignItems: 'center', marginTop: 18, cursor: 'pointer'}}
            >
                <Calendar height={24} width={24} color={Color.text.high}/>
                <Text type='p' style={{marginLeft: 8}}>
                    {moment(props.product.endDate?.iso).format('DD MMMM YYYY')}
                </Text>
            </Row>
            <Row
                style={{alignItems: 'center', marginTop: 18, cursor: 'pointer'}}
            >
                <Building height={24} width={24} color={Color.text.high}/>
                <Text type='p' style={{marginLeft: 8}}>
                    {props.event.shortAddress}
                </Text>
            </Row>
            <Row
                style={{alignItems: 'center', marginTop: 18, cursor: 'pointer'}}
            >
                <Map height={24} width={24} color={Color.text.high}/>
                <Text type='p' style={{marginLeft: 8}}>
                    {props.event.address}
                </Text>
            </Row>
        </Card>
        </>
	);
}

export default SectionMain;
export interface Props{
    product: ProductScheme,
    event: EventScheme,
    setEvent: (event: EventScheme) => void
    setProduct: (product: ProductScheme) => void
}
import React from "react";
import styled from "styled-components";
import media from "styled-media-query";

import { Color, Text } from "@adoptaunabuelo/react-components";
import { useEffect, useState } from "react";

const Container = styled.div``;
const Cell = styled.div`
	display: flex;
	flex-direction: row;
	padding: 8px 0px;
	align-items: center;
`;
const Image = styled.img`
	height: 48px;
	width: 48px;
	border-radius: 100px;
	object-fit: cover;
	margin-right: 16px;
`;
const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	border-bottom: 1px solid ${Color.line.soft};
	align-items: center;
`;

const PriceBreakdownCell = (props: Props) => {
	const [goal, setGoal] = useState(0);

	useEffect(() => {
		if (Array.isArray(props.options)) {
			const temp = props.options.reduce(
				(acc, item) => acc + (item.amount.eur || 0),
				0
			);
			setGoal(temp);
		} else {
			setGoal(0);
		}
	}, [props.options]);

	return (
		<Container style={props.style}>
			{Array.isArray(props.options) && props.options.length > 0 ? (
				props.options.map((item, index) => (
					<Cell
						key={"distribution-" + index}
						style={{ justifyContent: "space-between" }}
					>
						{item.image && <Image src={item.image} />}
						<Row style={{ minHeight: item.image ? 48 : 32 }}>
							<Text
								type="p"
								style={{
									fontFamily: "DM Mono",
									display: "flex",
									flex: 1,
									marginRight: 24,
								}}
							>
								{item.title}
							</Text>
							<Text type="p" style={{ fontFamily: "DM Mono" }}>
								{item.amount.eur} €
							</Text>
						</Row>
					</Cell>
				))
			) : (
				<Text
					type="p"
					style={{ fontStyle: "italic", color: Color.text.medium }}
				>
					Vacío
				</Text>
			)}
			<Cell style={{ justifyContent: "flex-end", marginTop: 12 }}>
				<Text
					type="h6"
					style={{ fontFamily: "DM Mono", fontWeight: 700 }}
				>
					Total: {goal} €
				</Text>
			</Cell>
		</Container>
	);
};
export default PriceBreakdownCell;
export interface Props {
	style?: React.CSSProperties;
	title?: string;
	options: Array<{
		title: string;
		image?: string;
		amount: {
			[key: string]: number;
		};
	}>;
}

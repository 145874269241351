import React from "react";
import styled from 'styled-components';
import { Constant, AdminTaskScheme, CoorporateScheme, PaymentMethodScheme, ProductScheme, ResidenceScheme, SubscriptionScheme, UserScheme } from 'client-v2';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { State } from '../../../redux';

import { Button, Text, Color, Label, Avatar, Menu } from '@adoptaunabuelo/react-components';
import { Phone, Globe, MapPin, User, ExternalLink, MessageCircle, School, HeartHandshake } from 'lucide-react';
import Card from '../../../components/Containers/Card'
import SectionPaymentMethod from "../../../components/Profile/SectionPaymentMethod";
import SectionSubscription from "../../../components/Profile/SectionSubscription";

const TitleHeader = styled.div`
	display: flex;
	justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid ${Color.line.soft};
`;
const Row = styled.div`
    display: flex;
    flex-direction: row;
`
const Column = styled.div`
    display: flex;
    flex-direction: column;
`
const MainSection = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 0px;
`
const InfoSection = styled.div`
    display: flex;
    flex-direction: column;
    padding: 16px 0px;
    border-top: 1px solid ${Color.line.soft};
`
const Cell = styled.div`
    display: flex;
    align-items: center;
    padding: 8px 0px;
`
const Title = styled.p`
    font-size: 24px; 
    font-weight: 600;
    font-family: 'Poppins';
    margin-bottom: 0px;
    margin-top: 0px;
    cursor: pointer;
    &:hover{
        text-decoration: underline;
    }
`
const Logo = styled.img`
	height: 40px;
	width: 40px;
	background-color: ${Color.background.soft};
	border-radius: 6px;
`

const SectionUser = (props: Props) =>{

    const callUser = useSelector((state:State)=>state.call ? state.call.user : undefined);
    const currentUser: UserScheme = useSelector((state:State)=>state.currentUser);
    const role = useSelector((state:State)=>state.role);
    const navigate = useNavigate();

    const goToProfile = () =>{
        if(props.user.type === 'grandpa')
            navigate('/abuelos/perfil?id='+props.user.objectId);
        else if(props.user.type === 'volunteer')
            navigate('/voluntarios/perfil?id='+props.user.objectId);
    }

    const onWhatsappChange = (op: any) =>{
        if(op){
            let message = undefined;
            const adminName = currentUser.name;
            const userName = props.user.name;
            if(op.id === 'missing_call_sales'){
                message = "Hola, "+userName+" 👋%0aSoy "+adminName+" de Adopta Un Abuelo 👵🏻❤. Te he llamado porque queremos darte las gracias por tu compromiso e informarte de nuestra comunidad.%0aCuando puedas devuélveme la llamada.%0a¡Gracias!"
            }
            window.open('whatsapp://send?phone='+props.user.phone+(message ? "&text="+message : ""))
        }
    }

	return (
        <>
        <Card style={{marginBottom:16}}>
            <TitleHeader>
                <Text type='p' weight="medium">
                    Solicitante
                </Text>
                <Row
                    style={{gap: 8}}
                >
                    <Menu
                        id={'whatsapp-menu'}
                        position="bottom-left"
                        menuStyle={{right: 0}}
                        Icon={
                            <Button
                                icon={<MessageCircle height={18} width={18}/>}
                                size={'small'}
                                style={{backgroundColor: '#25D366'}}
                                disabled={callUser}
                            >
                                Whatsapp
                            </Button>
                        }
                        options={props.task.group === 'sales' ? [
                            {
                                id: 'missing_call_sales',
                                label: 'Llamada perdida'
                            },
                            {
                                id: 'other',
                                label: 'Abrir chat'
                            }
                        ] : [
                            {
                                id: 'missing_call_sales',
                                label: 'Llamada perdida'
                            },
                            {
                                id: 'other',
                                label: 'Abrir chat'
                            }
                        ]}
                        onClick={onWhatsappChange}
                    />
                    <Button
                        icon={<Phone height={18} width={18}/>}
                        size={'small'}
                        disabled={callUser}
                        onClick={props.onCallClick}
                    >
                        Llamar
                    </Button>
                </Row>
            </TitleHeader>
            <MainSection>
                <Row style={{justifyContent: 'space-between', alignItems: 'center'}}>
                    <Avatar
                        style={{height: 80, width: 80, fontSize: 28}}
                        name={props.user.name}
                        icon={props.user.image?.url}
                    />
				</Row>
                <Row style={{alignItems: 'center'}}>
                    <Title onClick={goToProfile}>
                        {props.user.name}
                    </Title>
                    {role.actions.newTab &&
                        <Button
                            design="image"
                            icon={<ExternalLink height={20} width={20} color={Color.text.high}/>}
                            onClick={() => {
                                if(props.user.type === 'grandpa')
                                    window.open('/abuelos/perfil/?id='+props.user.objectId, '_blank');
                                else if(props.user.type === 'volunteer')
                                    window.open('/voluntarios/perfil?id='+props.user.objectId, '_blank');
                            }}
                        />
                    }
                </Row>
                <Row
                    style={{marginBottom: 12}}
                >
                    <Label text={props.user.state}/>
                </Row>
                {props.user.birthday &&
                    <Text type='p' style={{marginBottom: 8}}>
                        {moment().diff(props.user.birthday.iso, 'years')} años, {moment(props.user.birthday.iso).format('DD MMMM YYYY')}
                    </Text>
                }
                {props.residence &&
                    <Row 
                        style={{marginBottom: 8, alignItems: 'center', cursor: 'pointer', backgroundColor: Color.background.deepBlueLow, width: 'fit-content', padding: '4px 8px', borderRadius: 6}}
                        onClick={() => {
                            if(props.residence)
                                navigate('/residencias/perfil/?id='+(props.residence.objectId))
                        }}
                    >
                        <School width={20} height={20} color={Color.text.high}/>
                        <Text type='p' style={{marginLeft: 8}}>
                            {props.residence.name}
                        </Text>
                    </Row>
                }
                {props.coorporate &&
                    <Row 
                        style={{alignItems: 'center', cursor: 'pointer'}}
                        onClick={() => {
                            if(props.coorporate)
                                navigate('/corporativo/perfil/?id='+(props.coorporate.objectId))
                        }}
                    >
                        <Logo src={props.coorporate.logo?.url}/>
                        <Text type='p' style={{marginLeft: 8}}>
                            {props.coorporate.name}
                        </Text>
                    </Row>
                }
                <Text type='p' style={{fontSize:12, color: Color.text.high}}>
					Registrado el {moment(props.user.createdAt).format('DD/MM/YYYY')}
				</Text>
            </MainSection>
            {props.task.group !== 'sales' &&
                <InfoSection>
                    <Text type='p' weight="semibold" style={{marginBottom: 13}}>
                        Información
                    </Text>
                    {props.user.data?.volunteering &&
                        <Cell>
                            <HeartHandshake height={20} width={20} color={Color.text.high}/>
                            <Column style={{marginLeft: 12}}>
                                <Text type='p' style={{fontSize: 12, color: Color.text.high}}>
                                    Tipo de voluntariado
                                </Text>
                                <Text type='p' key='gender-cell'>
                                    {props.user.data.volunteering[0] === 'offline' ? 'Presencial' : props.user.data.volunteering[0] === 'online' ? 'Telefónico' : '-'}
                                </Text>
                            </Column>
                        </Cell>
                    }
                    <Cell>
                        <User height={20} width={20} color={Color.text.high}/>
                        <Column style={{marginLeft: 12}}>
                            <Text type='p' style={{fontSize: 12, color: Color.text.high}}>
                                Género
                            </Text>
                            {[props.user.gender].map((userGender) =>{
                                const gender = Constant.gender().filter((temp) => temp.id === userGender)
                                return(
                                    <Text type='p' key='gender-cell'>
                                        {gender[0] ? gender[0].title : '-'}
                                    </Text>
                                )
                            })}
                        </Column>
                    </Cell>
                    <Cell>
                        <MapPin height={20} width={20} color={Color.text.high}/>
                        <Column style={{marginLeft: 12}}>
                            <Text type='p' style={{fontSize: 12, color: Color.text.high}}>
                                Dirección
                            </Text>
                            <Text type='p'>
                                {props.user.address ? props.user.address : '-'}
                            </Text>
                        </Column>
                    </Cell>
                    <Cell>
                        <Globe height={20} width={20} color={Color.text.high}/>
                        <Column style={{marginLeft: 12}}>
                            <Text type='p' style={{fontSize: 12, color: Color.text.high}}>
                                Idiomas
                            </Text>
                            <Text type='p'>
                                {(props.user.data && props.user.data.languages) ? props.user.data.languages.map((language)=>{
                                    const temp = Constant.languages().filter((temp) => temp.id === language);
                                    return temp.length > 0 ? temp[0].title+', ' : '-'
                                }) : '-'}
                            </Text>
                        </Column>
                    </Cell>
                </InfoSection>
            }
            {props.task.group === 'sales' &&
                <>
                <InfoSection>
                    <SectionSubscription
                        user={props.user}
                        subscription={props.subscription}
                        product={props.product}
                        paymentMethods={props.paymentMethods}
                    />
                </InfoSection>
                <InfoSection>
                    <SectionPaymentMethod
                        user={props.user}
                        paymentMethods={props.paymentMethods}
                    />
                </InfoSection>
                </>
            }
        </Card>
        </>
	);
}

export default SectionUser;
export interface Props{
    user: UserScheme,
    coorporate?: CoorporateScheme,
    residence?: ResidenceScheme,
    task: AdminTaskScheme,
    subscription?: SubscriptionScheme,
    product?: ProductScheme,
    paymentMethods?: Array<PaymentMethodScheme>
    onCallClick: () => void
}
import React, { useState } from "react";
import { Challenge, ChallengeScheme } from "client-v2";
import styled from "styled-components";

import { Modal, Input, Color, Button } from "@adoptaunabuelo/react-components";
import { Euro, Image as ImageIcon, Trash } from "lucide-react";

const Container = styled.div`
	display: flex;
	flex-direction: column;
`;
const Cell = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	margin: 4px 0px;
	gap: 8px;
`;
const Image = styled.img`
	height: 40px;
	width: 40px;
	border-radius: 6px;
`;

const DistributionModal = (props: Props) => {
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState<string | undefined>(undefined);
	const [selectedDistribution, setSelectedDistribution] = useState(
		props.challenge.distribution || []
	);
	const [newDistribution, setNewDistribution] = useState<{
		title?: string;
		amount?: number;
		image?: string;
	}>({
		title: undefined,
		amount: undefined,
		image: undefined,
	});

	const onSave = () => {
		setLoading(true);
		Challenge.set(props.challenge.objectId, {
			distribution: selectedDistribution,
		})
			.then((challenge) => {
				setLoading(false);
				props.onSave && props.onSave(challenge.data);
			})
			.catch((e: string) => {
				setLoading(false);
				setError(e);
			});
	};

	const addDistribution = () => {
		if (newDistribution.title && newDistribution.amount) {
			if (props.challenge.type === "birthday" && !newDistribution.image) {
				setError("Debes añadir una foto");
			} else {
				setSelectedDistribution([
					...selectedDistribution,
					{
						title: newDistribution.title,
						amount: {
							eur: newDistribution.amount,
							usd: newDistribution.amount,
						},
						image: newDistribution.image,
					},
				]);
			}
		} else {
			setError("Debes añadir un título y una cantidad antes de añadir");
		}
	};

	const onRemoveClick = (index: number) => {
		const temp = selectedDistribution;
		temp.splice(index, 1);
		setSelectedDistribution([...temp]);
	};

	const onEdit = (type: "title" | "value", value: string, index: number) => {
		const temp = selectedDistribution;
		temp.splice(
			index,
			1,
			type === "title"
				? {
						...temp[index],
						title: value,
				  }
				: {
						...temp[index],
						amount: {
							eur: parseInt(value),
							usd: parseInt(value),
						},
				  }
		);
		setSelectedDistribution([...temp]);
	};

	return (
		<Modal
			isVisible={props.isVisible}
			onClose={() => {
				setSelectedDistribution(props.challenge.distribution || []);
				props.onClose();
			}}
			title={"Editar distribución"}
			error={error}
			buttonProps={{
				children: "Guardar",
				loading: loading,
				onClick: onSave,
			}}
		>
			<Container>
				{/* Validación antes de map */}
				{selectedDistribution.length > 0 &&
					selectedDistribution.map((item, index) => (
						<Cell key={"cell-" + index}>
							{props.challenge.type === "birthday" && (
								<Image src={item.image} />
							)}
							<Input
								containerStyle={{ display: "flex", flex: 1 }}
								type="text"
								placeholder="Título"
								defaultValue={item.title}
								onChange={(e) =>
									onEdit("title", e.target.value, index)
								}
							/>
							<Input
								icon={
									<Euro
										height={16}
										width={16}
										color={Color.text.high}
									/>
								}
								containerStyle={{ width: 100 }}
								type="number"
								placeholder="€"
								defaultValue={item.amount.eur}
								onChange={(e) =>
									onEdit("value", e.target.value, index)
								}
							/>
							<Button
								design="image"
								icon={<Trash height={20} width={20} />}
								onClick={() => onRemoveClick(index)}
							/>
						</Cell>
					))}
				<Cell
					style={{
						marginTop: 24,
						marginBottom: 32,
						paddingTop: 24,
						borderTop: "1px solid " + Color.line.soft,
					}}
				>
					{props.challenge.type === "birthday" && (
						<Input
							type="image"
							maxHeight={1000}
							maxWidth={1000}
							icon={
								<ImageIcon
									style={{ marginTop: 6 }}
									height={20}
									width={20}
								/>
							}
							buttonStyle={{
								padding: 0,
								height: 40,
								width: 40,
								minWidth: 0,
								borderRadius: 6,
							}}
							onChange={(image: string) => {
								setError(undefined);
								setNewDistribution({
									...newDistribution,
									image: image,
								});
							}}
						/>
					)}
					<Input
						containerStyle={{ display: "flex", flex: 1 }}
						type="text"
						placeholder="Título"
						onChange={(e) => {
							setError(undefined);
							setNewDistribution({
								...newDistribution,
								title: e.target.value,
							});
						}}
					/>
					<Input
						containerStyle={{ width: 100 }}
						type="number"
						placeholder="€"
						onChange={(e) => {
							setError(undefined);
							setNewDistribution({
								...newDistribution,
								amount: parseInt(e.target.value),
							});
						}}
					/>
					<Button
						design="secondary"
						size="small"
						onClick={addDistribution}
					>
						Añadir
					</Button>
				</Cell>
			</Container>
		</Modal>
	);
};
export default DistributionModal;
export interface Props {
	isVisible: boolean;
	challenge: ChallengeScheme;
	onSave: (challenge: ChallengeScheme) => void;
	onClose: () => void;
}

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { User, Leads, UserScheme, Payment, Subscription } from "client-v2";
import { json2csv } from "json-2-csv";
import moment from "moment";

import { Circle, ChevronsDown, ChevronsUp, Equal } from "lucide-react";
import {
	Modal,
	Text,
	ColorV2,
	Select,
	Avatar,
	Button,
	Tabs,
	Filter,
} from "@adoptaunabuelo/react-components";

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 8px;
`;
const Cell = styled.div`
	display: flex;
	flex-direction: row;
	gap: 6px;
	cursor: pointer;
	padding-bottom: 8px;
	padding: 5px 24px;
	&:hover {
		background-color: ${ColorV2.surface.neutralSoft};
	}
`;
const Column = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
`;
const Row = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	gap: 8px;
`;
const PriorityView = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	height: 24px;
	width: 24px;
	border-radius: 3px;
`;
const Header = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	position: sticky;
	top: 85px;
	background-color: white;
	margin-bottom: 8px;
	border-bottom: 1px solid ${ColorV2.border.neutralSoft};
	padding: 0px 24px 16px;
`;
const Footer = styled.div`
	position: sticky;
	bottom: 53px;
	display: flex;
	padding: 12px 24px;
	background-color: ${ColorV2.surface.background};
	gap: 12px;
	z-index: 10;
`;
const WarningView = styled.div`
	display: flex;
	flex-direction: column;
	padding: 12px;
	background-color: ${ColorV2.surface.redSoft};
	border-radius: 12px;
	margin-bottom: 8px;
`;
const ResultCell = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 12px;
	border: 1px solid ${ColorV2.border.neutralSoft};
	padding: 12px;
	gap: 4px;
`;
const FilterView = styled.div`
	display: flex;
	flex-direction: row;
	gap: 4px;
`;

const DownloadLeadsModal = (props: Props) => {
	const [step, setStep] = useState(0);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");
	const [selectedOption, setSelectedOption] = useState<any>(undefined);
	const [salesAdminArray, setSalesAdminArray] = useState<
		Array<{ id: string; label: string; icon?: React.ReactElement }>
	>([]);
	const [selectedArrayAdmin, setSelectedArrayAdmin] = useState<
		Array<{ id: string }>
	>([]);
	const [selectedLimit, setSelectedLimit] = useState({ label: "100" });
	const [options, setOptions] = useState<Array<any>>([]);
	const [selectedTab, setSelectedTab] = useState<string>("ticket");
	const [months, setMonths] = useState<Array<{ label: string }>>([]);
	const [selectedMonth, setSelectedMonth] = useState(
		moment().format("MMMM YYYY")
	);
	const [leadsFilters, setLeadsFilters] = useState<{
		birthdayMin?: Date;
		birthdayMax?: Date;
		gender?: string;
	}>({});
	const [result, setResult] = useState<{
		success: number;
		errors: number;
		urls: Array<{
			provider: string;
			url?: string;
			success: number;
			errors: number;
		}>;
	}>({
		success: 0,
		errors: 0,
		urls: [],
	});

	const menuOption = [
		{
			id: "leads",
			title: "👥 Nuevos leads",
			subtitle: "Descarga nuevos leads por categorías.",
		},
		{
			id: "bank",
			title: "🏦 Remesa de cobros",
			subtitle:
				"Descarga de remesa de cobro, incluyendo todos los cargos pendientes de cobro hasta la fecha.",
		},
		{
			id: "noContact",
			title: "❌📞 No contactar",
			subtitle:
				"Descarga todos los leads a los que no se debe contactar. Socios actuales y leads no válidos.",
		},
		{
			id: "failed",
			title: "❌💰 Primera cuota fallida",
			subtitle:
				"Descarga los socios cuya primera cuota no se haya podido cobrar. Solo aquellas dadas de alta por proveedores externos.",
		},
	];

	useEffect(() => {
		if (props.isVisible) {
			const tab = "ticket";
			setStep(0);
			getAdmins(tab);
			setSelectedTab(tab);
			getOptions(tab);
			getMonths();
		}
	}, [props.isVisible]);

	const getAdmins = (tab: string) => {
		setLoading(true);
		User.get({
			type: "admin",
			data: {
				department: tab === "ticket" ? "sales" : "sales-external",
			},
			active: true,
		}).then((result) => {
			const temp = result.data.map((item) => {
				return {
					id: item.objectId,
					label: item.name + " " + item.surname,
					icon: (
						<Avatar
							style={{ height: 24, width: 24, fontSize: 14 }}
							name={item.name}
							icon={item.image?.url}
						/>
					),
				};
			});
			setSalesAdminArray(temp);
			setLoading(false);
		});
	};

	const getMonths = () => {
		const temp = [];
		for (var i = 0; i < 12; i++) {
			const month = moment().subtract(i, "month").format("MMMM YYYY");
			temp.push({ label: month });
		}
		setMonths(temp);
	};

	const getLeadCount = async (type: string) => {
		setLoading(true);
		Leads.count({
			group: type as any,
			filter: leadsFilters,
		}).then((result) => {
			const temp = options.map((item) => {
				if (item.id === type)
					return {
						...item,
						pending: result.data.pending,
						amount: result.data.count,
					};
				else return item;
			});
			setOptions(temp);
			setLoading(false);
		});
	};

	const getOptions = (tabId: string) => {
		setSelectedTab(tabId);
		getAdmins(tabId);
		if (tabId === "ticket") {
			setSelectedLimit({ label: "100" });
			setOptions([
				{
					id: "event",
					title: "🗓️ Eventos",
					subtitle: "Usuarios que se han apuntado a un evento",
					priority: "high",
					amount: 0,
				},
				{
					id: "registered",
					title: "🔥 Registrados < 30 día",
					subtitle:
						"Registro completo en los últimos 30 días sin formación.",
					priority: "medium",
					amount: 0,
				},
				{
					id: "volunteers",
					title: "👵🏻 Voluntarios",
					subtitle:
						"Están participando como voluntarios pero no están como socios activos.",
					priority: "low",
					amount: 0,
				},
			]);
		} else if (tabId === "call") {
			setSelectedLimit({ label: "5000" });
			setOptions([
				{
					id: "payment",
					title: "💶 Puntuales",
					subtitle:
						"Han donado de manera puntual en algún sueño, firma, cumpleaños o donación.",
					priority: "high",
					pending: 0,
					amount: 0,
				},
				{
					id: "exSubscriptor",
					title: "💶❌ Ex-socios",
					subtitle: "Ha sido socio en algún momento.",
					priority: "high",
					pending: 0,
					amount: 0,
				},
				{
					id: "signature",
					title: "📝 Firmas",
					subtitle:
						"Ha firmado en alguna campaña pero no ha realizado donación.",
					priority: "high",
					pending: 0,
					amount: 0,
				},
				{
					id: "volunteer",
					title: "👵🏻 Voluntarios",
					subtitle: "Es voluntario en la actualidad.",
					priority: "low",
					pending: 0,
					amount: 0,
				},
				{
					id: "exVolunteer",
					title: "👵🏻❌ Ex-voluntarios",
					subtitle: "Ha sido voluntario en algún momento.",
					priority: "low",
					pending: 0,
					amount: 0,
				},
				{
					id: "birthdayLetter",
					title: "💌🎂 Felicitaciones",
					subtitle:
						"Ha enviado una felicitación en algún cumpleaños pero no ha donado.",
					priority: "low",
					pending: 0,
					amount: 0,
				},
				{
					id: "letter",
					title: "💌 Cartas",
					subtitle: "Ha enviado una carta pero no ha donado.",
					priority: "low",
					pending: 0,
					amount: 0,
				},
				{
					id: "inactive",
					title: "❄️ Inactivos",
					subtitle:
						"Leads válidos pero que no pertenecen a ninguno de los grupos anteriores.",
					priority: "low",
					pending: 0,
					amount: 0,
				},
			]);
		}
	};

	const onLoadClick = async () => {
		if (step === 0) {
			if (selectedOption.id === "leads") setStep(1);
			else if (selectedOption.id === "noContact") {
				setLoading(true);
				Leads.getNoContact()
					.then((result) => {
						setResult({
							success: result.data.success,
							errors: 0,
							urls: [
								{
									provider: "Adopta Un Abuelo",
									url: result.data.url,
									success: result.data.success,
									errors: 0,
								},
							],
						});
						setLoading(false);
						setStep(3);
					})
					.catch((e: string) => {
						setLoading(false);
						setError(e);
					});
			} else if (selectedOption.id === "bank") {
				setLoading(true);
				Payment.getXML()
					.then((result) => {
						//Download XML
						if (result.data.firstTime && result.data.firstTime.xml)
							downloadFile(
								result.data.firstTime.xml,
								"remesa_socios_primero.xml"
							);
						if (result.data.recurrent && result.data.recurrent.xml)
							downloadFile(
								result.data.recurrent.xml,
								"remesa_socios_recurrente.xml"
							);
						if (result.data.uniques && result.data.uniques.xml)
							downloadFile(
								result.data.uniques.xml,
								"remesa_pago_unico.xml"
							);

						//Download errors
						if (result.data.errors.length > 0) {
							downloadCSV(result.data.errors, "bank_erros.csv");
						}
						setResult({
							success: result.data.count,
							errors: result.data.errors.length,
							urls: [],
						});
						setLoading(false);
						setStep(3);
					})
					.catch((e: string) => {
						setLoading(false);
						setError(e);
					});
			} else if (selectedOption.id === "failed") {
				setStep(2);
			}
		} else if (step === 1) {
			let adminId = selectedArrayAdmin.map((item) => item.id);
			if (!adminId) {
				setError("Para cargar leads debes asignarlos a un admin");
			} else {
				setLoading(true);
				const limit = parseInt(selectedLimit.label);
				Leads.get({
					group: selectedOption.id,
					adminId: adminId,
					limit: limit,
					filter: leadsFilters,
				}).then(async (result) => {
					let totalSuccess = 0;
					const result2 = result.data.map((i) => {
						totalSuccess = totalSuccess + i.success;
						return {
							...i,
							errors: 0,
						};
					});
					setResult({
						success: totalSuccess,
						errors: 0,
						urls: result2,
					});
					setLoading(false);
					setStep(3);
				});
			}
		} else if (step === 2) {
			if (selectedMonth) {
				setLoading(true);
				const startDate = moment(selectedMonth, "MMMM YYYY")
					.startOf("month")
					.toDate();
				const endDate = moment(selectedMonth, "MMMM YYYY")
					.endOf("month")
					.toDate();
				Subscription.getFailedForProvideers({
					startDate: startDate,
					endDate: endDate,
				})
					.then((result) => {
						setResult({
							success: result.data.count,
							errors: 0,
							urls: [
								{
									provider: "Adopta Un Abuelo",
									url: result.data.url,
									success: result.data.count,
									errors: 0,
								},
							],
						});
						setLoading(false);
						setStep(3);
					})
					.catch((e: string) => {
						setLoading(false);
						setError(e);
					});
			} else {
				setError(
					"Debes seleccionar un rango de fecha antes de continuar"
				);
			}
		} else {
			props.onClose();
		}
	};

	const downloadCSV = (json: any, fileName: string) => {
		//Generate CSV
		const csv = json2csv(json, {
			emptyFieldValue: "",
		});

		//Download file
		downloadFile(csv, fileName);
	};

	const downloadFile = (data: string, fileName: string) => {
		//Download file
		const url = window.URL.createObjectURL(new Blob([data]));
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", fileName);
		document.body.appendChild(link);
		link.click();
		link.remove();
	};

	return (
		<Modal
			isVisible={props.isVisible}
			title={
				step === 0
					? "Descarga"
					: step === 1
					? "Descarga de leads"
					: step === 2
					? "Intervalo de fechas"
					: "Resultado"
			}
			subtitle={
				step === 0
					? "Selecciona el archivo que deseas generar"
					: step === 1
					? "Selecciona el segmento de leads para crear la campaña"
					: step === 2
					? "Selecciona el intervalo de fechas sobre el que obtener los datos"
					: ""
			}
			contentStyle={{ padding: 0 }}
			buttonProps={{
				children:
					step === 0
						? selectedOption &&
						  (selectedOption.id === "leads" ||
								selectedOption.id === "failed")
							? "Siguiente"
							: "Descargar"
						: step === 1
						? selectedTab === "ticket"
							? "Cargar tickets"
							: "Descargar"
						: step === 2
						? "Descargar"
						: "Finalizar",
				loading: loading,
				disabled: selectedOption ? false : true,
				onClick: onLoadClick,
			}}
			error={error}
			onClose={props.onClose}
		>
			{step === 0 ? (
				<Container>
					{menuOption.map((item, index) => {
						const selected = selectedOption
							? selectedOption.id === item.id
							: false;
						return (
							<Cell
								key={"menu-option-" + index}
								onClick={() => setSelectedOption(item)}
							>
								<Circle
									height={20}
									width={20}
									color={
										selected
											? "transparent"
											: ColorV2.text.neutralMedium
									}
									fill={
										selected
											? ColorV2.text.primary
											: "transparent"
									}
								/>
								<Column>
									<Row>
										<Text type="p2" weight="medium">
											{item.title}
										</Text>
									</Row>
									<Text
										type="c1"
										style={{
											color: ColorV2.text.neutralMedium,
										}}
									>
										{item.subtitle}
									</Text>
								</Column>
							</Cell>
						);
					})}
				</Container>
			) : step === 1 ? (
				<>
					<Header>
						<Tabs
							options={[
								{
									id: "ticket",
									title: "Interno",
								},
								{
									id: "call",
									title: "Telemarketing",
								},
							]}
							onChange={(op) => {
								setError("");
								getOptions(op.id);
							}}
						/>
						<FilterView>
							<Filter
								id="gender-filter"
								type="single"
								placeholder="Género"
								hideSearchBar={true}
								position="bottom-left"
								menuStyle={{ width: 180 }}
								options={[
									{
										id: "male",
										label: "Hombre",
									},
									{
										id: "female",
										label: "Mujer",
									},
								]}
								onChange={(ops) => {
									setLeadsFilters({
										...leadsFilters,
										gender:
											ops.length > 0
												? ops[0].id
												: undefined,
									});
								}}
							/>
							<Filter
								id="age-filter"
								type="single"
								placeholder="Edad"
								hideSearchBar={true}
								position="bottom-left"
								menuStyle={{ width: 180 }}
								options={[
									{
										id: "less",
										label: "< 25 años",
									},
									{
										id: "more",
										label: ">= 25 años",
									},
								]}
								onChange={(ops) => {
									const date = moment().subtract(25, "years");
									const birthdayFilter =
										ops.length > 0
											? ops[0].id === "less"
												? { birthdayMax: date.toDate() }
												: { birthdayMin: date.toDate() }
											: undefined;
									setLeadsFilters({
										...birthdayFilter,
									});
								}}
							/>
						</FilterView>
					</Header>
					<Container>
						{options.map((item, index) => {
							const selected = selectedOption
								? selectedOption.id === item.id
								: false;
							return (
								<Cell
									key={"lead-option-" + index}
									onClick={() => setSelectedOption(item)}
								>
									<Circle
										height={20}
										width={20}
										color={
											selected
												? "transparent"
												: ColorV2.text.neutralMedium
										}
										fill={
											selected
												? ColorV2.text.primary
												: "transparent"
										}
									/>
									<Column>
										<Row>
											<Text type="p2" weight="medium">
												{item.title}
											</Text>
											<PriorityView
												style={{
													backgroundColor:
														item.priority === "high"
															? ColorV2.surface
																	.redSoft
															: item.priority ===
															  "medium"
															? ColorV2.surface
																	.secondarySoft
															: ColorV2.surface
																	.neutralSoft,
												}}
											>
												{item.priority === "high" ? (
													<ChevronsUp
														height={16}
														width={16}
														color={ColorV2.text.red}
													/>
												) : item.priority ===
												  "medium" ? (
													<Equal
														height={16}
														width={16}
														color={
															ColorV2.surface
																.secondary
														}
													/>
												) : (
													<ChevronsDown
														height={16}
														width={16}
														color={
															ColorV2.text.primary
														}
													/>
												)}
											</PriorityView>
											{item.amount || item.pending ? (
												<Text
													type="p2"
													weight="medium"
													style={{
														display: "flex",
														flex: 1,
														justifyContent:
															"flex-end",
													}}
												>
													{item.pending}{" "}
													<span
														style={{
															fontSize: 10,
															marginLeft: 2,
															marginTop: 2,
														}}
													>
														{" (Total: " +
															item.amount +
															")"}
													</span>
												</Text>
											) : (
												<Button
													design="call-to-action"
													style={{
														flex: 1,
														fontSize: 12,
														justifyContent:
															"flex-end",
													}}
													loading={loading}
													onClick={() =>
														getLeadCount(item.id)
													}
												>
													Calcular
												</Button>
											)}
										</Row>
										<Text
											type="c1"
											style={{
												color: ColorV2.text
													.neutralMedium,
											}}
										>
											{item.subtitle}
										</Text>
									</Column>
								</Cell>
							);
						})}
					</Container>
					<Footer>
						<Column>
							<Text type="c1">Asignar a</Text>
							<Select
								id={"choose-provider"}
								optionStyle={{ top: "unset", bottom: 42 }}
								options={salesAdminArray}
								onChange={(op) => {
									setError("");
									setSelectedArrayAdmin(op);
								}}
							/>
						</Column>
						<Column>
							<Text type="c1">Límite</Text>
							<Select
								id="limit-select"
								optionStyle={{ top: "unset", bottom: 42 }}
								options={
									selectedTab === "ticket"
										? [
												{ label: "5" },
												{ label: "10" },
												{ label: "20" },
												{ label: "50" },
												{ label: "100" },
										  ]
										: [
												{ label: "500" },
												{ label: "1000" },
												{ label: "2500" },
												{ label: "5000" },
										  ]
								}
								selectedItem={selectedLimit}
								onChange={(op) => setSelectedLimit(op)}
							/>
						</Column>
					</Footer>
				</>
			) : step === 2 ? (
				<Container style={{ marginBottom: 32 }}>
					<Select
						id="month-select"
						options={months}
						onChange={(op) => setSelectedMonth(op.label)}
					/>
				</Container>
			) : (
				<Container style={{ marginBottom: 32 }}>
					{result.errors > 0 && (
						<WarningView>
							<Text type="p" weight="semibold">
								🚨 Descarga de errores
							</Text>
							<Text type="p2">
								Parece que hubo algún error con alguno de los
								elementos. Se ha descargado automáticamente un
								documento con los elementos fallidos.{" "}
								<span style={{ fontWeight: 600 }}>
									Inténtalo de nuevo SOLO con los elementos
									erroneos
								</span>
								.
							</Text>
						</WarningView>
					)}
					{result.urls.map((url) => (
						<ResultCell>
							<Row>
								<Text type="p" weight="semibold">
									{url.provider}
								</Text>
							</Row>
							<Row>
								<Text type="p2">
									Correctos:{" "}
									<span
										style={{
											color: ColorV2.text.green,
											fontWeight: 600,
										}}
									>
										{url.success}
									</span>
								</Text>
								<Text type="p2">
									Errores:{" "}
									<span
										style={{
											color: ColorV2.text.red,
											fontWeight: 600,
										}}
									>
										{url.errors}
									</span>
								</Text>
							</Row>
							{url.url && (
								<Button
									design="call-to-action"
									size="small"
									onClick={() =>
										window.open(url.url, "_blank")
									}
								>
									Abrir CSV
								</Button>
							)}
						</ResultCell>
					))}
				</Container>
			)}
		</Modal>
	);
};
export default DownloadLeadsModal;
export interface Props {
	isVisible: boolean;
	currentUser: UserScheme;
	onClose: () => void;
}

import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import {
	ErrorScheme,
	Residence,
	ResidenceGroup,
	ResidenceGroupScheme,
	ResidenceScheme,
} from "client-v2";
import { Constant } from "client-v2";
import {
	Input,
	Text,
	Button,
	Color,
	Checkbox,
	Feedback,
} from "@adoptaunabuelo/react-components";
import AdminBar from "../../../components/Nav/AdminBar";

const Container = styled.div`
	position: relative;
	display: flex;
	width: -webkit-fill-available;
	height: 100vh;
	flex-direction: column;
	padding: 0px 32px 32px;
`;
const RegisterContainer = styled.div`
	display: flex;
	flex: 1;
	justify-content: center;
`;
const RegisterView = styled.div`
	display: flex;
	flex-direction: column;
	width: 600px;
	background-color: white;
	padding: 24px 48px;
	border-radius: 12px;
`;
const Row = styled.div`
	display: flex;
	flex-direction: row;
`;
const Col = styled.div`
	display: flex;
	flex-direction: column;
`;
const SearchView = styled.div`
	position: relative;
	display: flex;
	flex: 1;
`;
const DropdownMenu = styled.div`
	position: absolute;
	z-index: 1000;
	width: 100%;
	background-color: white;
	overflow: hidden;
	border-radius: 12px;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.08);
	top: 64px;
`;
const SuggestionView = styled.div`
	display: flex;
	flex: 1;
	align-items: center;
	padding: 12px 16px;
	cursor: pointer;
	background-color: white;
	&:hover {
		background-color: ${Color.background.soft};
	}
`;

const Register = (props: Props) => {
	const navigate = useNavigate();
	const [timer, setTimer] = useState<any>(undefined);
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState<string | undefined>(undefined);
	const [phone, setPhone] = useState<{
		value: string | undefined;
		countryCode: string;
	}>({
		value: undefined,
		countryCode: "+34",
	});
	const [error, setError] = useState<string | undefined>(undefined);
	const [address, setAddress] = useState<string | undefined>(undefined);
	const [sortAddress, setSortAddress] = useState<string | undefined>(
		undefined
	);
	const [coordinates, setCoordinates] = useState<
		undefined | google.maps.LatLngLiteral
	>(undefined);
	const [selectedPrograms, setSelectedPrograms] = useState<Array<string>>([]);
	const [groupOptions, setGroupOptions] = useState<ResidenceGroupScheme[]>(
		[]
	);
	const [residenceGroup, setResidenceGroup] = useState<any>(undefined);
	const [residenceFocus, setResidenceFocus] = useState(false);

	const onSaveClick = async () => {
		if (
			name &&
			phone.value &&
			phone.countryCode &&
			sortAddress &&
			selectedPrograms.length > 0 &&
			residenceGroup
		) {
			setLoading(true);
			const temp = selectedPrograms.map((item: any) => item.id);
			const temp2 = {
				letters: temp.includes("letters"),
				offline: temp.includes("offline"),
				call: temp.includes("call"),
			};
			Residence.create({
				name: name,
				phone: phone.countryCode + phone.value,
				address: address,
				shortAddress: sortAddress,
				residenceGroupId: residenceGroup.objectId,
				location: {
					latitude: coordinates?.lat,
					longitude: coordinates?.lng,
				},
				type: temp2,
			})
				.then((result) => {
					setLoading(false);
					navigate("/residencias/perfil/?id=" + result.data.objectId);
				})
				.catch((error: ErrorScheme) => {
					setLoading(false);
					setError(error.message);
				});
		} else {
			if (!name) setError("Debe añadir el nombre de la residencia");
			else if (!phone.value)
				setError("Debes añadir un número de teléfono válido");
			else if (!sortAddress)
				setError("Debes añadir una dirección válida");
			else if (selectedPrograms.length === 0)
				setError(
					"Debes seleccionar al menos un programa para la residencia"
				);
			else if (!residenceGroup)
				setError(
					"Debes seleccionar un grupo residencial. Si la residencia no pertenece a un grupo, selecciona Independiente"
				);
		}
	};

	const onNameChange = (e: any) => {
		setError(undefined);
		setName(e.target.value);
	};

	const onPhoneChange = (e: any) => {
		setError(undefined);
		if (e.isValid)
			setPhone({
				value: e.value,
				countryCode: e.country,
			});
	};

	const onGroupChange = (e: any) => {
		if (timer) {
			clearTimeout(timer);
			setTimer(undefined);
		}
		setTimer(
			setTimeout(() => {
				const text = e.target.value;
				searchResidenceGroup(text);
			}, 500)
		);
	};

	const onLocationChange = async (result: {
		address: string;
		geocoder: google.maps.GeocoderResult;
		location: google.maps.LatLngLiteral;
	}) => {
		const localidad = result.geocoder.address_components.filter((it) =>
			it.types.includes("locality")
		)[0].long_name;
		const provincia =
			result.geocoder.address_components.filter((it) =>
				it.types.includes("administrative_area_level_2")
			).length > 0
				? result.geocoder.address_components.filter((it) =>
						it.types.includes("administrative_area_level_2")
				  )[0].long_name
				: result.geocoder.address_components.filter((it) =>
						it.types.includes("administrative_area_level_1")
				  )[0].long_name;
		const country = result.geocoder.address_components.filter((it) =>
			it.types.includes("country")
		)[0].long_name;

		setAddress(result.address);
		setCoordinates(result.location);
		setSortAddress(localidad + ", " + provincia + ", " + country + ".");
	};

	const searchResidenceGroup = async (name: string) => {
		setLoading(true);
		setResidenceGroup(undefined);
		ResidenceGroup.get({
			name: name,
			limit: 5,
		})
			.then((result) => {
				setGroupOptions(result.data);
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setLoading(false);
			});
	};

	const onResidenceGroupClick = (item: any) => {
		setResidenceGroup(item);
		setResidenceFocus(false);
		setGroupOptions([]);
	};

	const handleProgramChange = (items: Array<string>) => {
		setSelectedPrograms(items);
	};

	return (
		<Container>
			<Feedback
				isVisible={error ? true : false}
				type="error"
				text={error ? error : ""}
				onClose={() => setError(undefined)}
			/>
			<AdminBar
				style={{ marginBottom: 16 }}
				title="Registro de residencia"
				goBack={() => navigate(-1)}
			/>
			<RegisterContainer>
				<RegisterView>
					<Text
						type="h3"
						weight="semibold"
						style={{ marginTop: 12, marginBottom: 24 }}
					>
						Datos personales
					</Text>
					<Row style={{ gap: 12, marginBottom: 12 }}>
						<Input
							type="text"
							design={"secondary"}
							containerStyle={{ flex: 1 }}
							placeholder="Nombre"
							onChange={onNameChange}
						/>
						<Input
							design={"secondary"}
							containerStyle={{ flex: 1 }}
							placeholder="Teléfono"
							type="tel"
							countryOptions={Constant.countries()}
							country={phone.countryCode}
							onPhoneChange={onPhoneChange}
						/>
					</Row>
					<Row style={{ marginBottom: 12 }}>
						<Input
							type="location"
							design="secondary"
							placeholder="Dirección"
							onLocationChange={onLocationChange}
						/>
					</Row>
					<Row style={{ marginBottom: 24 }}>
						<SearchView>
							<Input
								type="text"
								placeholder="Grupo residencial"
								design={"secondary"}
								value={
									residenceGroup
										? residenceGroup.name
										: undefined
								}
								containerStyle={{ flex: 1 }}
								onChange={onGroupChange}
								onFocus={() => setResidenceFocus(true)}
							/>
							{residenceFocus && (
								<DropdownMenu>
									{groupOptions.map((item: any) => (
										<SuggestionView
											key={item.id}
											onClick={() =>
												onResidenceGroupClick(item)
											}
										>
											<Text
												type="p2"
												style={{
													textOverflow: "ellipsis",
												}}
											>
												{item.name}
											</Text>
										</SuggestionView>
									))}
								</DropdownMenu>
							)}
						</SearchView>
					</Row>
					<Col>
						<Text
							type="p2"
							style={{ marginBottom: 12, color: Color.text.high }}
						>
							Programas
						</Text>
						<Checkbox
							type="multiple"
							options={[
								{
									id: "offline",
									label: "Visitas",
								},
								{
									id: "call",
									label: "Llamadas",
								}
							]}
							onChange={(items: any) =>
								handleProgramChange(items)
							}
						/>
					</Col>
					<Row
						style={{
							flex: 1,
							justifyContent: "flex-end",
							alignItems: "flex-end",
						}}
					>
						<Button loading={loading} onClick={onSaveClick}>
							Guardar
						</Button>
					</Row>
				</RegisterView>
			</RegisterContainer>
		</Container>
	);
};
export default Register;
export interface Props {}

import React, { useState, useEffect } from "react";
import {
	Residence,
	ResidenceGroup,
	ResidenceScheme,
	ResidenceGroupScheme,
	ErrorScheme,
} from "client-v2";
import axios from "axios";
import { Constant } from "client-v2";
import { Input, Modal, Dropdown } from "@adoptaunabuelo/react-components";

const ResidenceInfoModal = (props: Props) => {
	const phoneUtil =
		require("google-libphonenumber").PhoneNumberUtil.getInstance();

	const [residenceGroupOptions, setResidenceGroupOptions] = useState<
		{ id: string; title: string }[]
	>([]);
	const [timer, setTimer] = useState<any>(undefined);
	const [loading, setLoading] = useState(false);
	const [name, setName] = useState<string | undefined>(undefined);
	const [address, setAddress] = useState<string | undefined>(undefined);
	const [shortAddress, setShortAddress] = useState<string | undefined>(
		undefined
	);
	const [location, setLocation] = useState<
		{ latitude: number; longitude: number } | undefined
	>(undefined);
	const [city, setCity] = useState<string | undefined>(undefined);
	const [country, setCountry] = useState<string | undefined>(undefined);
	const [postalCode, setPostalCode] = useState<string | undefined>(undefined);
	const [timeZone, setTimeZone] = useState<string | undefined>(undefined);
	const [phone, setPhone] = useState<{
		value: string | undefined;
		countryCode: string | undefined;
	}>({
		value: undefined,
		countryCode: undefined,
	});
	const [residenceGroupId, setResidenceGroupId] = useState<
		{ id: string; title: string } | undefined
	>(
		props.residenceGroup
			? {
					id: props.residenceGroup.objectId,
					title: props.residenceGroup.name,
			  }
			: undefined
	);
	const [error, setError] = useState<string>("");

	useEffect(() => {
		if (props.residence.phone) {
			const result = phoneUtil.parseAndKeepRawInput(
				props.residence.phone
			);
			const values = result.values_;
			setPhone({
				value: values[2].toString(),
				countryCode: "+" + values[1],
			});
		} else {
			setPhone({
				value: undefined,
				countryCode: "+34",
			});
		}
		if (props.residence.address) {
			setAddress(props.residence.address);
			setShortAddress(props.residence.shortAddress);
			setLocation({
				latitude: props.residence.location.latitude,
				longitude: props.residence.location.longitude,
			});
		}
	}, []);

	const onSave = async () => {
		setLoading(true);
		Residence.set(props.residence.objectId, {
			name: name,
			address: address,
			shortAddress: shortAddress,
			location: location,
			country: country,
			city: city,
			zipCode: postalCode,
			timeZone: timeZone,
			phone:
				phone.countryCode && phone.value
					? phone.countryCode + phone.value
					: undefined,
			residenceGroupId: residenceGroupId?.id,
		})
			.then((residence) => {
				setLoading(false);
				props.onSave(residence.data);
			})
			.catch((error: ErrorScheme) => {
				setError(error.message);
				setLoading(false);
			});
	};

	const onLocationChange = async (item: {
		address: string;
		geocoder: google.maps.GeocoderResult;
		location: google.maps.LatLngLiteral;
	}) => {
		const locality = item.geocoder.address_components.filter((it) =>
			it.types.includes("locality")
		);
		const city = item.geocoder.address_components.filter((it) =>
			it.types.includes("administrative_area_level_2")
		);
		const country = item.geocoder.address_components.filter((it) =>
			it.types.includes("country")
		);
		const postal_code = item.geocoder.address_components.filter((it) =>
			it.types.includes("postal_code")
		);
		const sortAddress =
			(locality.length > 0 ? locality[0].long_name + ", " : "") +
			(city.length > 0 ? city[0].long_name + ", " : "") +
			country[0].long_name;
		setCountry(country[0].short_name);
		setCity(city.length > 0 ? city[0].long_name : undefined);
		setAddress(item.address);
		setShortAddress(sortAddress);
		setPostalCode(
			postal_code.length > 0 ? postal_code[0].long_name : undefined
		);

		//Get the timezone
		const googleAPIKey = "AIzaSyA_H7WVmlnxy8OWrNuIJmGclYWwXFB49Wk";
		const result2 = await axios({
			method: "GET",
			url:
				"https://maps.googleapis.com/maps/api/timezone/json?location=" +
				item.location.lat +
				"%2C" +
				item.location.lng +
				"&timestamp=1331161200&key=" +
				googleAPIKey,
		});
		if (result2.data.status === "OK") {
			setTimeZone(result2.data.timeZoneId);
		}
	};

	const onNameChange = (e: any) => {
		setName(e.target.value);
	};

	const onPhoneChange = (e: any) => {
		if (e.isValid) {
			setPhone({
				countryCode: e.country,
				value: e.value,
			});
		}
	};

	const onSearch = (text: string) => {
		if (timer) {
			clearTimeout(timer);
			setTimer(undefined);
		}
		setTimer(
			setTimeout(() => {
				searchResidenceGroup(text);
			}, 500)
		);
	};

	const searchResidenceGroup = async (text: string) => {
		setLoading(true);
		ResidenceGroup.get({
			name: text,
			limit: 5,
		})
			.then((result) => {
				const options = result.data.map((item) => {
					return {
						id: item.objectId,
						title: item.name,
					};
				});
				setResidenceGroupOptions(options);
				setLoading(false);
			})
			.catch((error: ErrorScheme) => {
				setLoading(false);
			});
	};

	return (
		<Modal
			type="form"
			isVisible={props.isVisible}
			style={{ width: 569, height: 500 }}
			contentStyle={{ height: "inherit" }}
			title={"Información"}
			onClose={props.onClose}
			buttonProps={{
				onClick: onSave,
				loading: loading,
			}}
			error={error}
			options={[
				{
					id: "name",
					title: "Nombre",
					Data: (
						<Input
							type="text"
							placeholder="Nombre"
							defaultValue={props.residence.name}
							onChange={onNameChange}
						/>
					),
				},
				{
					id: "address",
					title: "Dirección",
					Data: (
						<Input
							type="location"
							design="primary"
							placeholder="Dirección exacta"
							defaultValue={address}
							onLocationChange={onLocationChange}
						/>
					),
				},
				{
					id: "phone",
					title: "Teléfono",
					Data: (
						<Input
							type="tel"
							placeholder="Teléfono"
							defaultValue={phone.value}
							country={phone.countryCode}
							countryOptions={Constant.countries()}
							onPhoneChange={onPhoneChange}
						/>
					),
				},
				{
					id: "residenceGroup",
					title: "Grupo",
					Data: (
						<Dropdown
							id={"residence-group-dropdown"}
							placeholder={"Grupo residencial"}
							type="single"
							options={residenceGroupOptions}
							selectedOptions={
								residenceGroupId
									? [residenceGroupId]
									: undefined
							}
							onSearchChange={(text) => onSearch(text)}
							onChange={(array: Array<any>) =>
								setResidenceGroupId(array[0])
							}
						/>
					),
				},
			]}
		/>
	);
};
export default ResidenceInfoModal;
export interface Props {
	isVisible: boolean;
	residence: ResidenceScheme;
	residenceGroup?: ResidenceGroupScheme;
	onClose: () => void;
	onSave: (residence: ResidenceScheme) => void;
}
